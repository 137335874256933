import { Avatar, Container, Link, ListItemText, TextField, Typography } from "@mui/material";
import axiosInstance from "../axiosApi";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import { usePlacesWidget } from "react-google-autocomplete";
import MuiAlert from '@mui/material/Alert';
import { Snackbar } from "@mui/material";
import { forwardRef } from "react";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@mui/material";

function Unauthorized() {

  return (
    <Container maxWidth="md">
        <div className="NotYetDeveloped">
            <p>You attempted to access a page you are not authorized to access. Try <Link href="/login">logging in</Link>.</p>
        </div>

    </Container>
  );
}

export default Unauthorized;

