import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useHistory, useParams } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  ElementsConsumer,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import axiosInstance from "../axiosApi";
import { Typography } from "@mui/material";
import axios from "axios";
const baseURL = "https://management-portal-api.herokuapp.com/api/";
//const baseURL = "http://127.0.0.1:8000/api/";

export default function PaymentCompleted() {
  var url = new URL(window.location.href);
  var payment_result = url.searchParams.get("success");
  var session_id = url.searchParams.get("id");

  let user = localStorage.getItem("username");
  let user_id = localStorage.getItem("user_id");
  const history = useHistory();

  const initialUserData = Object.freeze({
    stripe_customer_id: "",
  });

  const initialFormData = Object.freeze({});

  const [userData, updateUserData] = useState(initialUserData);
  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // get user info
  useEffect(() => {
    axiosInstance.get(user_id).then((res) => {
      updateFormData(res.data);
    });
  }, [updateFormData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const URL = baseURL + user_id + "/";
    let postData = new FormData();
    console.log(formData)
    for (var key in formData) {
      let temp = formData[key];
      if (temp) {
        postData.append(key, temp);
      }
    }
    axios
    .put(URL, postData, config)
    .then((res) => {
      console.log(res);
    })
    .catch(function (error) {
      console.log(error)
    });
  };

  const [errorHandle, setErrorHandle] = useState({
    open: false,
    message: null,
    severity: null,
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorHandle({ open: false });
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
        <Stepper activeStep={4} sx={{ mt: 4 }}>
          <Step key={1}>
            <StepLabel>Verification</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Onboarding Information</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>Appointment With Carrier</StepLabel>
          </Step>
          <Step key={4}>
            <StepLabel>Payment</StepLabel>
          </Step>
        </Stepper>
      <div>
        <Typography align="center" component="h1" variant="h5" my={4}>
          Congratulations!
        </Typography>
        <Typography component="h1" variant="body2" align="left" my={4}>
          You will receive an email with details regarding your Six Week
          Training. In the meantime, please confirm the information below.
        </Typography>

        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Divider>Business Card Information</Divider>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                We provide your business cards. Please complete the information
                below. You will be able to reorder business cards at no cost
                after you launch.
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="first_name"
                name="first_name"
                label="First Name"
                fullWidth
                onChange={handleChange}
                value={formData.first_name || ""}
                variant="outlined"
                helperText="First name as it will appear on your business card."
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="last_name"
                name="last_name"
                label="Last Name"
                fullWidth
                onChange={handleChange}
                value={formData.last_name || ""}
                variant="outlined"
                helperText="Last name as it will appear on your business card."
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="phone_number"
                name="phone_number"
                label="Phone Number"
                value={formData.phone_number || ""}
                onChange={handleChange}
                helperText="Phone number as it will appear on your business card."
              />
            </Grid>

            <Grid item xs={12}>
              <Divider>Additional Information</Divider>
            </Grid>

            <Grid item xs={12}>
              <FormHelperText>
                We provide your first two shirts. We have three styles to choose
                from - Short Sleeve polos, Long Sleeve polos, and Long sleeve
                oxfords, sizes M-2XL in black. You can purchase additional
                shirts at cost ($25 each) any time.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="shirt_style-label">Shirt Style</InputLabel>
                <Select
                  labelId="shirt_style-label"
                  id="tshirt_style"
                  label="Shirt Style"
                  onChange={handleChange}
                  value={formData.tshirt_style || 0}
                  variant="outlined"
                  name="tshirt_style"
                >
                  <MenuItem value={0}>None Selected</MenuItem>
                  <MenuItem value={1}>Short Sleeve Polo</MenuItem>
                  <MenuItem value={2}>Long Sleeve Polo</MenuItem>
                  <MenuItem value={3}>Long Sleeve Oxford</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="shirt_size-label">Shirt Size</InputLabel>
                <Select
                  labelId="shirt_size-label"
                  id="tshirt_size"
                  label="Shirt Size"
                  onChange={handleChange}
                  value={formData.tshirt_size || 0}
                  variant="outlined"
                  name="tshirt_size"
                >
                  <MenuItem value={0}>None Selected</MenuItem>
                  <MenuItem value={1}>S</MenuItem>
                  <MenuItem value={2}>M</MenuItem>
                  <MenuItem value={3}>L</MenuItem>
                  <MenuItem value={4}>XL</MenuItem>
                  <MenuItem value={5}>2XL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                Once you begin your training, we will add you to our Voxer team,
                which is one of our main communication resources so you can be
                plugged in with our trainers and other coordinators. Voxer is a
                free walkie talkie app that you'll need to download onto your
                smart phone. Go to your App Store and download “Voxer”. Once
                it's downloaded on your phone, go to Your Profile in the top
                left corner in order to view the username that Voxer has
                assigned to you. It's typically your name followed by numbers.
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="voxer"
                name="voxer"
                label="Voxer Handle"
                fullWidth
                onChange={handleChange}
                value={formData.voxer || ""}
                variant="outlined"
                helperText=""
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
