import {
  CircularProgress,
  Container,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Chart } from "chart.js";
import React, { Component, useEffect, useRef, useState } from "react";
import axiosInstance from "../axiosApi";

class PipeDataComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <div>{this.props.data.pipe.name}</div>
        <div>{this.props.data.pipe.cards_count} applicants</div>
        <div>
          {this.props.data.pipe.phases.map((item) => (
            <div>
              {item.name} - {item.cards_count}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

function SourceChartBar() {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    response: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Contracted Agents by Source",
      },
    },
  });

  useEffect(() => {
    axiosInstance.get("sources").then(function (response) {
      myChartRef = chartRef.current.getContext("2d");
      if (typeof myChart !== "undefined") myChart.destroy();
      console.log(response.data);
      data = {
        labels: response.data.map((item) => item.source),
        datasets: [
          {
            label: "Agents",
            data: response.data.map((item) => item.count),
            backgroundColor: "rgb(54, 162, 235)",
            borderColor: "rgba(54, 162, 235, 0.2)",
            lineTension: 0.1,
          },
        ],
      };

      setChartData(data);

      myChart = new Chart(myChartRef, {
        type: "bar",
        data: data,
        options: chartOptions,
      });
    });
  }, []);

  return (
    <div>
      <canvas id="UserCountBar" ref={chartRef}></canvas>
    </div>
  );
}

function TurnoverChartBar() {
  const chartNewRef = useRef();
  let myNewChart;
  let data;
  let myNewChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    response: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Turnover Rate by Source",
      },
    },
  });

  useEffect(() => {
    axiosInstance.get("turnover").then(function (response) {
      myNewChartRef = chartNewRef.current.getContext("2d");
      if (typeof myNewChart !== "undefined") myNewChart.destroy();
      console.log(response.data);
      data = {
        labels: response.data.turnover.map((item) => item.source),
        datasets: [
          {
            label: "Turnover %",
            data: response.data.turnover.map((item) => item.turnover),
            backgroundColor: "rgb(54, 162, 235)",
            borderColor: "rgba(54, 162, 235, 0.2)",
            lineTension: 0.1,
          },
        ],
      };

      setChartData(data);

      myNewChart = new Chart(myNewChartRef, {
        type: "bar",
        data: data,
        options: chartOptions,
      });
    });
  }, []);

  return (
    <div>
      <canvas id="UserCountBar" ref={chartNewRef}></canvas>
    </div>
  );
}

export default function Recruiting() {
  const [pipeData, setPipeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedComponent, setLoadedComponent] = useState();
  const [totalTurnover, setTotalTurnover] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const options = {
    method: "POST",
    url: "https://api.pipefy.com/graphql",
    headers: {
      Accept: "application/json",
      Authorization:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJ1c2VyIjp7ImlkIjozNDk1NTgsImVtYWlsIjoiYW5kcmV3QGNvbnNvbGlkYXRlZGFzc3VyYW5jZS5jb20iLCJhcHBsaWNhdGlvbiI6MzQzNTR9fQ.lXbXH3BuwFK81NteL2F0k1QgMhhzDbiXhN3l8Fc-1xkZujE-gU2qdOl_-5rdLIWXTeE2K1chN1nAODirZdI_ug",
      "Content-Type": "application/json",
    },
    data: {
      query:
        '{pipe(id:"301591734") {name cards_count phases {id name cards_count}} }',
      // '{cards(pipe_id:301591734, after:"WyIyMDIxLTA0LTE2IDE0OjI3OjQ0LjIxNjA0MzAwMCBVVEMiLCIxNS4wIiw0MTcxMzk5MTZd") { edges { node { id title created_at }} pageInfo {hasNextPage endCursor}}}',
    },
  };

  useEffect(() => {
    axios
      .request(options)
      .then(function (response) {
        setPipeData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
    axiosInstance.get("turnover").then(function (response) {
      setTotalTurnover(response.data.total_turnover);
      setTotalActive(response.data.active);
      setTotalUsers(response.data.total);
    });
  }, [setPipeData]);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
        <iframe src="https://consolidatedassurance.us.qlikcloud.com/single/?appid=33952138-40b2-4c6c-ba35-046818e3171a&obj=xjRJFgA"></iframe>
 {/*
      <Typography variant="h6">Total Active: {totalActive}</Typography>
      <Typography variant="h6">Total Agents: {totalUsers}</Typography>
      <Typography variant="h6">Total Turnover: {totalTurnover.toFixed(2)}%</Typography>
      <Typography variant="body">Note: Turnover rate is not yet accurate due to missing data.</Typography>
      <Typography variant="h6">{pipeData.data.pipe.name}</Typography>
      <div>{pipeData.data.pipe.cards_count} applicants</div>
      <div>
        {pipeData.data.pipe.phases.map((item) => (
          <div>
            {item.name} - {item.cards_count}
          </div>
        ))}
      </div> 
      <SourceChartBar />
      <TurnoverChartBar />*/}
    </Container>
  );
}
