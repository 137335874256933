import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../axiosApi";
import { Chart } from "chart.js";

let backgroundColors = {
  DominiqueOverstreet: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
  tylerbutler: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
  AdrianaPantoja: {
    backgroundColor: "rgb(255, 150, 0, 0.6)",
    borderColor: "rgb(255, 150, 0)",
  },
  jerryyoung: {
    backgroundColor: "rgb(255, 150, 0, 0.6)",
    borderColor: "rgb(255, 150, 0)",
  },
  NatashaSmithJones: {
    backgroundColor: "rgb(100, 100, 250, 0.6)",
    borderColor: "rgb(100, 100, 250)",
  },
  LatoshaGordon: {
    backgroundColor: "rgb(0, 150, 0, 0.6)",
    borderColor: "rgb(0, 150, 0)",
  },
  LatoyaWilliams: {
    backgroundColor: "rgb(150, 0, 0, 0.6)",
    borderColor: "rgb(150, 0, 0)",
  },
  CiarraPass: {
    backgroundColor: "rgb(0, 150, 0, 0.6)",
    borderColor: "rgb(0, 150, 0)",
  },
  debbieastorr: {
    backgroundColor: "rgb(54, 162, 235, 0.6)",
    borderColor: "rgba(54, 162, 235)",
  },
};

export default function DailyResolveTotalByAgentChart() {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    response: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Daily Resolves",
      },
    },
  });

  useEffect(() => {
    axiosInstance
      .get("agentresolves/daily/")
      .then(function (response) {
        myChartRef = chartRef.current.getContext("2d");

        if (typeof myChart !== "undefined") myChart.destroy();

        let labels = response.data.map(function (e) {
          return e.month + "/" + e.day + "/" + e.year;
        });

        labels = [...new Set(labels)];
        console.log(labels)

        let agents = response.data.map(function (e) {
          return e.agent;
        });

        var datasets = [];
        var temp_var;

        [...new Set(agents)].map(function (e) {
          var dataloop = [];
          var temp_labels = [];
          response.data.filter(function (item) {
            temp_var = item.month + "/" + item.day + "/" + item.year;
            if (item.agent === e) {
              dataloop.push(item.total);
              temp_labels.push(temp_var);
            }
          });
          labels.map(function (d) {
            if (temp_labels.indexOf(d) === -1) {
              dataloop.splice(labels.indexOf(d), 0, null);
            }
          });
          datasets.push({
            label: e,
            data: dataloop,
            backgroundColor: backgroundColors[e]["backgroundColor"],
            borderColor: backgroundColors[e]["borderColor"],
            borderWidth: 3,
            borderRadius: 3,
          });
        });

        // let longest = 0;
        // datasets.map((a) => {
        //   if(a.data.length > longest) longest=a.data.length;
        // });

        // datasets.map((a) => {
        //   let start_length = a.data.length;
        //   console.log(start_length)
        //   for(var i=1; i<= (longest - start_length); i++) {
        //     a.data.unshift(null);
        //   }
        // });

        data = {
          labels: [...new Set(labels)],
          datasets: datasets,
        };

        setChartData(data);

        myChart = new Chart(myChartRef, {
          type: "line",
          data: data,
          options: chartOptions,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [setIsLoading]);

  return (
    <div>
      <canvas id="WeeklyRetentionTotalsByAgentChart" ref={chartRef} />
    </div>
  );
}
