import {
  Alert,
  Avatar,
  Container,
  createStyles,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import axiosInstance from "../axiosApi";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { List } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemAvatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import { NavLink } from "react-router-dom";
import { Box, createTheme } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridLinkOperator,
} from "@mui/x-data-grid-pro";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import axios from "axios";
import { usePlacesWidget } from "react-google-autocomplete";
import { red } from "@mui/material/colors";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";

LicenseInfo.setLicenseKey(
  "0e809a6170113d88e7e206a32fe7266bT1JERVI6MzA2ODcsRVhQSVJZPTE2NjU1MjA0MjcwMDAsS0VZVkVSU0lPTj0x"
);

const defaultTheme = createTheme();

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.secondary}`,
        },
      },
    }),
  { defaultTheme }
);

function CustomToolbar() {
  return (
    <GridToolbarContainer justifyItems="right">
      <div style={{ flex: 1, textAlign: "left" }}></div>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function EditToolbar(props) {
  const { apiRef } = props;
  const classes = useStyles();

  const handleClick = () => {
    const id = 2;
    apiRef.current.updateRows([{ id, isNew: true }]);
    apiRef.current.setRowMode(id, "edit");
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });

      apiRef.current.setCellFocus(id, "first_name");
    });
  };

  return (
    <GridToolbarContainer>
      <div style={{ flex: 1, textAlign: "left" }}>
        <Button
          color="primary"
          textAlign="left"
          startIcon={<AddIcon />}
          onClick={() => window.location = '/adduser'}
        >
          Add Coordinator
        </Button>
        <Button
          color="primary"
          textAlign="left"
          startIcon={<EmailIcon />}
          onClick={() => navigator.clipboard.writeText(props.emailList)}
        >
          Copy Emails
        </Button>
      </div>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />

      <div className={classes.root}>
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? "visible" : "hidden" }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </div>
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  apiRef: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  emailList: PropTypes.array.isRequired,
};

function Home() {
  const baseURL = "https://management-portal-api.herokuapp.com/api/";
  //const baseURL = "http://127.0.0.1:8000/api/";
  const history = useHistory();
  const user_id = localStorage.getItem("user_id");
  const [userInfo, setUserInfo] = useState([]);
  const [userList, setUserList] = useState([]);
  const [origUserList, setOrigUserList] = useState([]);
  const [license, setLicense] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [emailList, setEmailList] = useState();

  const initialFormData = Object.freeze({
    searchBar: "",
  });
  const [formData, updateFormData] = useState(initialFormData);
  const apiRef = useGridApiRef();
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "edit");
  };

  const handleSaveClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.commitRowChange(id);
    apiRef.current.setRowMode(id, "view");

    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      axiosInstance
        .post(`user/register/`, {
          email: row.email,
          first_name: row.first_name,
          last_name: row.last_name,
          phone_number: row.phone_number,
          writing_number: row.writing_number,
        })
        .then((result) => {
          axiosInstance.get().then((res) => {
            setUserList(res.data);
          });
        });
    } else {
      let config = {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
        },
      };
      let URL = baseURL + row.id + "/";
      let postData = new FormData();
      if (!license) {
        delete formData.license;
      }
      for (var key in row) {
        let temp = row[key];
        if (temp) {
          postData.append(key, temp);
        }
      }
      axios
        .put(URL, postData, config)
        .then((res) => {
          if (res.status == 200) {
            console.log("success");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    apiRef.current.updateRows([{ ...row, isNew: false }]);
  };

  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
    const row = apiRef.current.getRow(id);
    axiosInstance.delete(row.id + "/").then((res) => {
      console.log(res);
    });
    apiRef.current.updateRows([{ id, _action: "delete" }]);
  };

  const handleFullEditClick = (id) => (event) => {
    event.stopPropagation();
    //history.push("/view/" + id + "/");
    const win = window.open("/view/" + id + "/", "_blank");
    win.focus();
  };

  const handleCancelClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "view");
    const row = apiRef.current.getRow(id);

    if (row.isNew) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    }
  };

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.toLowerCase(),
    });
  };

  useEffect(() => {
    axiosInstance.get(user_id).then((res) => {
      setUserInfo(res.data);
    });
  }, [setUserInfo]);

  useEffect(() => {
    axiosInstance.get().then((res) => {
      var email_list = '';
      setUserList(res.data);
      res.data.map((each) => {
        if(each.status !== 'Inactive' && each.status !== 'Onboarding') {
        if (each.email) {
          email_list = email_list + each.email + ';';
        }
        if (each.personal_email) {
          email_list = email_list + each.personal_email + ';';
        }
      }
      });
      setOrigUserList(res.data);
      setEmailList(email_list);
    });
  }, [setUserList]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyCskE87HFoEDuYr_GP6IYQcLc-Efc2Uc-Q",
    inputAutocompleteValue: "country",
    options: {
      types: "address",
    },
    onPlaceSelected: (place) => {
      console.log(place);
    },
  });

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = origUserList.filter((row) => {
      return Object.keys(row).some((field) => {
        if (row[field]) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setUserList(filteredRows);
  };

  const renderAddressEditCell = (p) => {
    return (
      <TextField
        required
        variant="outlined"
        id="address"
        name="address"
        label="Address"
        fullWidth
        inputRef={materialRef}
      />
    );
  };

  function getTerritoryName(p) {
    if (p.row.territory) {
      return p.row.territory.territory;
    } else {
      return "X - Unassigned";
    }
  }

  const renderAssignedTerritory = (p) => {
    if (p.row.territory) {
      return (
        <Link
          to={"/territories/" + p.row.territory.id}
          style={{ textDecoration: "none" }}
        >
          {p.row.territory.territory}
        </Link>
      );
    } else {
      return "X - Unassigned";
    }
  };
  const columns = [
    { field: "first_name", headerName: "First Name", flex: 1, editable: true },
    { field: "last_name", headerName: "Last Name", flex: 1, editable: true },
    {
      field: "territory",
      headerName: "Territory",
      flex: 1,
      editable: false,
      valueGetter: getTerritoryName,
      renderCell: (params) => renderAssignedTerritory(params),
    },
    { field: "email", headerName: "Email", flex: 1, editable: true },
    {
      field: "phone_number",
      headerName: "Phone Number",
      flex: 1,
      editable: true,
    },
    {
      field: "voxer",
      headerName: "Voxer",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "welcome_date",
      headerName: "Welcome Date",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "exam_date",
      headerName: "Exam Date",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "writing_number",
      headerName: "Writing Number",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "npn",
      headerName: "NPN",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: (params) => {
        return (
          <CopyToClipboard text={params.formattedValue} onCopy={handleClick}>
            <span>{params.formattedValue}</span>
          </CopyToClipboard>
        );
      },
    },
    {
      field: "business_entity",
      headerName: "Business Entity",
      flex: 1,
      editable: true,
      hide: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: true,
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      editable: true,
    },
    {
      field: "launch_date",
      headerName: "Launch Date",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<ManageAccountsIcon />}
            label="Full View"
            onClick={handleFullEditClick(id)}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={handleDeleteClick(id)}
          //   color="inherit"
          // />,
        ];
      },
    },
  ];

  const [filterModel, setFilterModel] = useState({
    items: [
      {
        id: 1,
        columnField: "status",
        operatorValue: "equals",
        value: "Active",
      },
      {
        id: 2,
        columnField: "status",
        operatorValue: "equals",
        value: "Onboarding",
      },
      {
        id: 3,
        columnField: "status",
        operatorValue: "equals",
        value: "Offboarding",
      },
      {
        id: 4,
        columnField: "status",
        operatorValue: "equals",
        value: "Training",
      },
      {
        id: 5,
        columnField: "status",
        operatorValue: "equals",
        value: "Awaiting Appointment",
      },
      {
        id: 6,
        columnField: "status",
        operatorValue: "equals",
        value: "Verification",
      },
      {
        id: 7,
        columnField: "status",
        operatorValue: "equals",
        value: "Awaiting Payment",
      },
      {
        id: 8,
        columnField: "status",
        operatorValue: "equals",
        value: "Pre-Training",
      },
    ],
    linkOperator: GridLinkOperator.Or,
  });

  return (
    <React.Fragment>
      <div className="NotYetDeveloped">
        <Typography variant="h6" mb={2} pb={0}>
          Master List
        </Typography>

        <div>
          <DataGridPro
            autoHeight
            density={"compact"}
            columns={columns}
            rows={userList}
            apiRef={apiRef}
            editMode="row"
            filterModel={filterModel}
            onFilterModelChange={(model) => setFilterModel(model)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            components={{
              Toolbar: EditToolbar,
              Footer: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                apiRef,
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
                emailList: emailList,
              },
            }}
          />
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="info">
          Address copied
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default Home;
