import React, { useState, useEffect, forwardRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import InfoIcon from '@mui/icons-material/Info';
import { Link as RouterLink } from 'react-router-dom';
import axiosInstance from '../axiosApi.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory, useParams } from 'react-router-dom';
import { usePlacesWidget } from "react-google-autocomplete";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Onboard() {
	const history = useHistory();
  let user = localStorage.getItem('username');
  let user_id = localStorage.getItem('user_id');
  const initialFormData = Object.freeze({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    user_id: '',
    userName: '',
    confirmPassword:'',
  });

  const reg_code = useParams().regcode

  const [formData, updateFormData] = useState(initialFormData);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [errorHandle, setErrorHandle ] = useState({
    open: false,
    message: null,
    severity: null,
});
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorHandle({open: false});
  };

  useEffect(() => {
    axiosInstance
      .get('regcode/' + reg_code)
      .then((res) =>  {
        updateFormData({
          ...formData,
          ['firstName']: res.data.first_name,
          ['lastName']: res.data.last_name,
          ['email']: res.data.email,
          ['user_id']: res.data.id,
          ['userName']: res.data.user_name,
        });
        user_id = res.data.id
        console.log(user_id);
        user = res.data.user_name
      });
  }, [updateFormData]);

  const { ref: materialRef } = usePlacesWidget({
    apiKey: 'AIzaSyCskE87HFoEDuYr_GP6IYQcLc-Efc2Uc-Q',
    onPlaceSelected: (place) => {
      updateFormData(formData => ({
        ...formData,
        'address': place.formatted_address,
      }))
    },
    inputAutocompleteValue: "country",
    options: {
      types: "address"
    },
  });
  
	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
    console.log('submitted');
		e.preventDefault();
    var err_msg = 'There was an error';
    let pass_check = true;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    let postData = new FormData();
    postData.append('first_name', formData.firstName);
    postData.append('last_name', formData.lastName);
    postData.append('email', formData.email);
    postData.append('password', formData.password);
    postData.append('id', formData.user_id);
    postData.append('user_name', formData.userName);
    if(!formData.password) {
      pass_check = false;
      err_msg = 'Please enter a password.'
    } else {
      if(formData.password !== formData.confirmPassword) {
        pass_check = false;
        err_msg = 'Your passwords do not match.'
      }
    }
    if(pass_check) {
      axiosInstance
        .put(formData.user_id + '/', postData)
        .then((res) => {
          console.log(res)
          if(res.status === 200) {
            setErrorHandle({
                open: true,
                message: 'User updated',
                severity: 'success',
            });
          }

          if(res.data.status !== 'Verification' ) {
            history.push('/login?reg=true');
          }
          
        })
        .catch(function(error) {
        
        try {
            if(error.response.data.non_field_errors[0]) {
              err_msg = error.response.data.non_field_errors[0];
            }
          } catch {}

          setErrorHandle({
              open: true,
              message: err_msg,
              severity: 'error',
          });
        });
      } else {
        setErrorHandle({
            open: true,
            message: err_msg,
            severity: 'error',
        });

      }
  }
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div align="center">
        <Typography component="h1" variant="h5" mt={4}>
          Welcome, {formData.firstName}
        </Typography>
        <Typography component="h1" variant="body1" mb={4}>
          Please confirm the following information.
        </Typography>
        <form noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                name="confirmPassword"
                autoComplete="password"
                type="password"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </Grid>
            
          </Grid>
          <Button
            fullWidth
            sx={{mt:4}}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </form>
      </div>
      <Snackbar
        open={errorHandle.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={errorHandle.severity}
          sx={{ width: "100%" }}
        >
          {errorHandle.message}
        </Alert>
      </Snackbar>
    </Container>
    
  );
}