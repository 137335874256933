import React, { useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import axios from "axios";
import { backgroundColors } from "./colorThemes.js";

const fetchChartData = async (apiEndpoint) => {
  try {
    const calltoolsAxiosInstance = axios.create({
      baseURL: "https://east-1.calltools.io/api/",
      timeout: 30000,
      headers: {
        Authorization: "Token 5d5f0948893af9149f08ec03613b5040d72b8f5d",
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });

    const response = await calltoolsAxiosInstance.get(apiEndpoint);
    const data = response.data.records
      .filter(obj => obj["Calls"] > 0)
      .map(obj => ({
        label: obj["Full Name"],
        data: obj["Phone On Call Time"] / 60,
      }))
      .sort((a, b) => b.data - a.data);

      const backgroundColorsArray = data.map(record => {
        console.log(record.label.replace(/\s/g, ''))
        const theme = backgroundColors[record.label.replace(/\s/g, '')]; // Remove spaces from label
        return theme ? theme.backgroundColor : "rgba(186, 63, 255, .7)"; // Default color if not found
      });
      
      const borderColorsArray = data.map(record => {
        const theme = backgroundColors[record.label.replace(/\s/g, '')]; // Remove spaces from label
        return theme ? theme.borderColor : "rgba(186, 63, 255, 1)"; // Default color if not found
      });
      
    return {
      labels: data.map(d => d.label),
      datasets: [
        {
          label: "Minutes",
          data: data.map(d => d.data),
          backgroundColor: backgroundColorsArray,
          borderColor: borderColorsArray,
          borderWidth: 3,
          borderRadius: 3,
        },
      ],
    };
  } catch (error) {
    console.error("Error fetching chart data:", error);
    return { labels: [], datasets: [] };
  }
};

export default function TalkTimeChart({ apiEndpoint, title }) {
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title, // Use the title prop here
      },
    },
    cutout: "50%",
  });

  useEffect(() => {
    let chartInstance;

    const setupChart = async () => {
      const chartData = await fetchChartData(apiEndpoint);

      if (chartRef && chartRef.current) {
        const context = chartRef.current.getContext("2d");
        chartInstance = new Chart(context, {
          type: "bar",
          data: chartData,
          options: chartOptions,
        });
      }
    };

    setupChart();

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [apiEndpoint, chartOptions]);

  return <div><canvas ref={chartRef} /></div>;
}
