import { Button, Container, Grid, LinearProgress } from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import _ from "lodash";

import RetentionQueueChart from "./charts/resolve_charts/RetentionQueueChart";
import HelpQueueChart from "./charts/resolve_charts/HelpQueueChart";
import SalesQueueChart from "./charts/resolve_charts/SalesQueueChart";
import AgentActivityChart from "./charts/resolve_charts/AgentActivityChart";
import TalkTimeChart from "./charts/resolve_charts/TalkTimeChart";
import CancelSaveChart from "./charts/resolve_charts/CancelSaveChart";
import AgentCampaignActivityChart from "./charts/resolve_charts/AgentCampaignActivityChart";
import DailyResolveTotalsByAgentChart from "./charts/resolve_charts/DailyRetentionTotalsByAgentChart";
import WeeklyRetentionTotalsByAgentChart from "./charts/resolve_charts/WeeklyRetentionTotalsByAgentChart";
import WeeklyRetentionTotalsChart from "./charts/resolve_charts/WeeklyRetentionTotalsChart";

export default function RetentionDashboard() {
  let numCharts = 5;
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = React.useState();

  const calltoolsAxiosInstance = axios.create({
    baseURL: "https://east-1.calltools.io/api/",
    timeout: 30000,
    headers: {
      Authorization: "Token 5d5f0948893af9149f08ec03613b5040d72b8f5d",
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  }

  async function ExecuteRequest(url, data) {
    // As this is a recursive function, we need to be able to pass it the prevous data. Here we either used the passed in data, or we create a new objet to hold our data.
    data = data || {};
    await calltoolsAxiosInstance.get(url).then((response) => {
      // We merge the returned data with the existing data
      _.mergeWith(data, response.data, customizer);
      // We check if there is more paginated data to be obtained
      if (response.data.next != null) {
        // If nextPageUrl is not null, we have more data to grab
        return ExecuteRequest(response.data.next, data);
      }
    });
    return data;
  }

  useEffect(() => {
    setIsLoading(false);
    //   811 - help desk
    //   812 - retention
    //   813 - sales
    //   860 - verification
    ExecuteRequest("useridsandnames/")
      .then(function (data) {
        // console.log(data)
    setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });

  }, [state]);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={4}>
          <HelpQueueChart />
        </Grid>
        <Grid item xs={4}>
          <RetentionQueueChart />
        </Grid>
        <Grid item xs={4}>
          <SalesQueueChart />
        </Grid> */}

        <Grid item xs={6} sx={{ mt: 4 }}>
          <AgentActivityChart
            apiEndpoint="agentperformance/"
            title="Agent Call Counts (Today)" />
        </Grid>

<Grid item xs={6} sx={{ mt: 4 }}>
  <AgentActivityChart
    apiEndpoint="agentperformance/?page_size=max&range=this%20week%20su%20s"
    title="Agent Call Counts (This Week)" />
</Grid>

        <Grid item xs={6} sx={{ mt: 4 }}>
          <DailyResolveTotalsByAgentChart
            apiEndpoint="agentresolves/today/"
            title="Self Recorded Resolves (Today)" />
        </Grid>
        
        <Grid item xs={6} sx={{ mt: 4 }}>
          <DailyResolveTotalsByAgentChart
            apiEndpoint="agentresolves/weeklytotal/"
            title="Self Recorded Resolves (This Week)" />
        </Grid>
        

        <Grid item xs={6} sx={{ mt: 4 }}>
          <AgentCampaignActivityChart
            apiEndpoint="campaignagentperformance/"
            chartTitle="Campaign Activity (Today)"
          />
        </Grid>

        <Grid item xs={6} sx={{ mt: 4 }}>
          <AgentCampaignActivityChart
            apiEndpoint="campaignagentperformance/?page_size=max&range=this%20week%20su%20s"
            chartTitle="Campaign Activity (This Week)"
          />
        </Grid>

        <Grid item xs={6} sx={{ mt: 4 }}>
          <TalkTimeChart
            apiEndpoint="agentperformance/"
            title="Minutes on Phone (Today)"
          />
        </Grid>
        <Grid item xs={6} sx={{ mt: 4 }}>
          <TalkTimeChart
            apiEndpoint="agentperformance/?page_size=max&range=this%20week%20su%20s"
            title="Minutes on Phone (This Week)"
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 4 }}>
          <WeeklyRetentionTotalsByAgentChart />
        </Grid>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <WeeklyRetentionTotalsChart />
        </Grid>

        <Grid item xs={12} sx={{ mt: 4 }}>
          <CancelSaveChart />
        </Grid>
      </Grid>
    </Container>
  );
}
