import axios from "axios";
//const baseURL = "https://management-portal-api.herokuapp.com/api/";
const baseURL = "https://portalapi.consolidatedassurance.com/api/";
//const baseURL = "http://127.0.0.1:8000/api/";


const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "JWT " + localStorage.getItem("access_token")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});


axiosInstance.interceptors.response.use(

  (response) => {
    if(localStorage.getItem("user_status") == 7 &&
    window.location.href.indexOf('onboarding') === -1 ) {
      return(window.location.href='/onboarding/')
    }
    if(localStorage.getItem("user_status") == 2 &&
    window.location.href.indexOf('appointment') === -1 ) {
      return(window.location.href='/appointment/')
    }
    if(localStorage.getItem("user_status") == 4 &&
    window.location.href.indexOf('payment') === -1 &&
    window.location.href.indexOf('completed') === -1 ) {
      return(window.location.href='/payment/')
    }
    if(localStorage.getItem("user_status") == 3 &&
    window.location.href.indexOf('completed') === -1 ) {
      return(window.location.href='/completed/')
    }
    if(localStorage.getItem("user_status") == 5 &&
    window.location.href.indexOf('onboarding') === -1 ) {
      return(window.location.href='/onboarding/')
    }
    return response
  },
  (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops

    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "token/refresh/"
    ) {
      window.location.href = "/login/";
      return Promise.reject(error);
    }

    if (
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      const refreshToken = localStorage.getItem("refresh_token");

      if (refreshToken) {
        const tokenParts = JSON.parse(atob(refreshToken.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          return (window.location.href = "/login/");

          // axiosInstance
          //   .post("/token/refresh/", { refresh: refreshToken })
          //   .then((response) => {
          //     console.log(response);
          //     localStorage.setItem("access_token", response.data.access);
          //     localStorage.setItem("refresh_token", response.data.refresh);
          //     localStorage.setItem("username", tokenParts.name);
          //     localStorage.setItem("user_id", tokenParts.user_id);

          //     axiosInstance.defaults.headers["Authorization"] =
          //       "JWT " + response.data.access;
          //     originalRequest.headers["Authorization"] =
          //       "JWT " + response.data.access;

          //     return axiosInstance(originalRequest);
          //   })
          //   .catch((err) => {
          //     console.log('failing here!!!')
          //     window.location.href = "/login/";
          //     console.log(err);
          //   });
        } else {
          window.location.href = "/login/";
        }
      } else {
        window.location.href = "/login/";
      }
    }

    if (error.response.status === 401 && originalRequest.url !== "/token/") {
      window.location.href = "/login/";
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
