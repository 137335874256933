import React, { useRef, useEffect, useState } from 'react';
import { Chart } from 'chart.js';
import axios from 'axios';

export default function AgentCampaignActivityChart({ apiEndpoint, chartTitle }) {
  const chartRef = useRef();
  const [chartInstance, setChartInstance] = useState(null);
  const [chartOptions] = useState({
    // ... your chart options ...
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
    cutout: '50%',
  });

  const calltoolsAxiosInstance = axios.create({
    baseURL: 'https://east-1.calltools.io/api/',
    timeout: 30000,
    headers: {
      Authorization: 'Token 5d5f0948893af9149f08ec03613b5040d72b8f5d', // Replace with a secure method
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });

  async function fetchChartData() {
    let data = await recursiveApiCall(apiEndpoint);
    processDataAndCreateChart(data);
  }

  async function recursiveApiCall(url, accumulatedData = {}) {
    const response = await calltoolsAxiosInstance.get(url);
    const mergedData = { ...accumulatedData, ...response.data };
    if (response.data.next != null) {
      return recursiveApiCall(response.data.next, mergedData);
    }
    return mergedData;
  }

  function processDataAndCreateChart(data) {
    const aggregatedData = {};

    data.records.forEach(obj => {
      if (!aggregatedData[obj['Full Name']]) {
        // Initialize a new entry for this Full Name
        aggregatedData[obj['Full Name']] = {
          inbound: 0,
          outbound: 0,
          total: 0,
        };
      }
  
      // Aggregate the data
      aggregatedData[obj['Full Name']].inbound += obj['Calls Inbound'];
      aggregatedData[obj['Full Name']].outbound += obj['Calls Outbound'];
      aggregatedData[obj['Full Name']].total += obj['Calls'];
    });

    const sortedData = Object.entries(aggregatedData).map(([name, data]) => ({
      name,
      ...data
    })).sort((a, b) => b.total - a.total);
  
    // Extract the sorted data into arrays
    const labels = sortedData.map(item => item.name);
    const inbound = sortedData.map(item => item.inbound);
    const outbound = sortedData.map(item => item.outbound);
    const total = sortedData.map(item => item.total);

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Outbound Calls',
          data: outbound,
          backgroundColor: ["rgba(63, 186, 255, .7)"],
          borderColor: ["rgba(63, 186, 255, 1)"],
          borderWidth: 3,
          borderRadius: 3,
        },
        // ... other datasets ...
      ],
    };

    if (chartInstance) {
      chartInstance.destroy();
    }

    const newChart = new Chart(chartRef.current.getContext('2d'), {
      type: 'bar', // or 'line', 'doughnut', etc.
      data: chartData,
      options: chartOptions,
    });

    setChartInstance(newChart);
  }

  useEffect(() => {
    fetchChartData();
    // Cleanup function to destroy chart instance on component unmount
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div>
    <canvas ref={chartRef} />
  </div>
);
}