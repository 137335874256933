import {
  Button,
  Container,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import axiosInstance from "../axiosApi";
import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";

function renderAgent(params) {
  const agents = {
    1: "Emileigh",
    2: "Jules",
    3: "Conner",
    4: "Kayla",
  };
  return agents[params.value];
}

function AgentEditInputCell(props) {
  const { id, value, api, field } = props;

  const handleChange = (event) => {
    api.setEditCellValue(
      { id, field, value: Number(event.target.value) },
      event
    );
  };

  const handleRef = (element) => {
    if (element) {
      element.querySelector(`input[value="${value}]"`).focus();
    }
  };

  return (
    <div>
      <FormControl fullWidth>
        <Select
          name="agent"
          value={Number(value)}
          onChange={handleChange}
          size="small"
          label="Agent"
        >
          <MenuItem value={1}>Emileigh</MenuItem>
          <MenuItem value={2}>Jules</MenuItem>
          <MenuItem value={3}>Conner</MenuItem>
          <MenuItem value={4}>Kayla</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

AgentEditInputCell.propTypes = {
  api: PropTypes.any.isRequired,
  field: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

function renderAgentEditInputCell(params) {
  return <AgentEditInputCell {...params} />;
}

function EditToolbar(props) {
  const { apiRef } = props;

  const handleClick = () => {
    const id = 0;
    apiRef.current.updateRows([{ id, isNew: true }]);
    apiRef.current.setRowMode(id, "edit");
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: 1,
      });

      apiRef.current.setCellFocus(id, "date");
    });
  };

  return (
    <GridToolbarContainer>
      <div style={{ flex: 1, textalign: "left" }}>
        <Button
          color="primary"
          textAlign="left"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add Entry
        </Button>
      </div>

      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

EditToolbar.propTypes = {
  apiRef: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
};

export default function RetentionManagement() {
  const [weeklySales, setWeeklySales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiRef = useGridApiRef();
  const [sortModel, setSortModel] = useState([
    {
      field: "date",
      sort: "desc",
    },
  ]);
  const [pageSize, setPageSize] = React.useState(15);
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "edit");
  };

  const handleSaveClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.commitRowChange(id);
    apiRef.current.setRowMode(id, "view");
    const row = apiRef.current.getRow(id);
    if (!row.isNew) {
      axiosInstance
        .put(`dailyretentionsales/all/`+row.id+`/`, {
          monthly_premium: Number(row.monthly_premium),
          resolved: row.resolved,
        })
        .then((result) => {
          axiosInstance.get(`dailyretentionsales/all/`).then((res) => {
            setWeeklySales(res.data);
          });
        });
    } else {
      axiosInstance
        .post(`dailyretentionsales/all/`, {
          date: moment(row.date).format("YYYY-MM-DD"),
          agent: row.agent,
          monthly_premium: row.monthly_premium,
          resolved: row.resolved,
        })
        .then((result) => {
          axiosInstance.get(`dailyretentionsales/all/`).then((res) => {
            setWeeklySales(res.data);
          });
        })
        .catch(function (error) {
          console.log(error);
        });;
    }
    apiRef.current.updateRows([{ ...row, isNew: false }]);
  };
  const handleCancelClick = (id) => (event) => {
    event.stopPropagation();
    apiRef.current.setRowMode(id, "view");
    const row = apiRef.current.getRow(id);

    if (row.isNew) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    }
  };

  const handleDeleteClick = (id) => (event) => {
    event.stopPropagation();
    const row = apiRef.current.getRow(id);
    axiosInstance
      .delete("dailyretentionsales/all/" + row.id + "/")
      .then((res) => {
        console.log(res);
      });
    apiRef.current.updateRows([{ id, _action: "delete" }]);
  };

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("dailyretentionsales/all/")
      .then(function (response) {
        setWeeklySales(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });

    setIsLoading(false);
  }, [setWeeklySales]);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      editable: true,
      type: "date",
    },
    {
      field: "agent",
      headerName: "Agent",
      flex: 1,
      editable: true,
      renderCell: renderAgent,
      renderEditCell: renderAgentEditInputCell,
    },
    {
      field: "monthly_premium",
      headerName: "Monthly Premium",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "resolved",
      headerName: "Resolved",
      flex: 1,
      editable: true,
      type: "number",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = apiRef.current.getRowMode(id) === "edit";

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ my: 4 }}>
      <DataGridPro
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        autoHeight
        density={"compact"}
        columns={columns}
        rows={weeklySales}
        editMode="row"
        apiRef={apiRef}
        editMode="row"
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        components={{
          Toolbar: EditToolbar,
          Footer: EditToolbar,
        }}
        componentsProps={{
          toolbar: { apiRef },
          footer: { apiRef },
        }}
      />
    </Container>
  );
}
