import {
  Button,
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import axiosInstance from "../axiosApi";
import { Bar, Line } from "react-chartjs-2";
import { rgba } from "@react-spring/shared";
import BarChartIcon from "@mui/icons-material/BarChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { setWeek } from "date-fns";
import WeeklyRetentionTotalsByAgentChart from "./charts/resolve_charts/WeeklyRetentionTotalsByAgentChart";
import WeeklyRetentionTotalsByAgentChartBar from "./charts/WeeklyRetentionTotalsByAgentChartBar";
import WeeklyResolveTotalsChart from "./charts/WeeklyResolveTotalsChart";
import WeeklyResolveTotalsChartBar from "./charts/WeeklyResolveTotalsChartBar";
import DailyResolveTotalsChart from "./charts/DailyResolveTotalsChart";
import DailyResolveTotalsChartBar from "./charts/DailyResolveTotalsChartBar";
import DailyResolveAPChart from "./charts/DailyResolveAPChart";
import DailyResolveAPChartBar from "./charts/DailyResolveAPChartBar";
import DailyResolveAPByAgentChart from "./charts/DailyResolveAPByAgentChart";
import DailyResolveAPByAgentChartBar from "./charts/DailyResolveAPByAgentChartBar";
import DailyResolveTotalByAgentChartBar from "./charts/DailyResolveTotalByAgentChartBar";
import DailyResolveTotalByAgentChart from "./charts/resolve_charts/DailyResolveTotalByAgentChart";
import MonthlyResolveTotalsChartBar from "./charts/MonthlyResolveTotalsChartBar";
import MonthlyResolveTotalsChart from "./charts/MonthlyResolveTotalsChart";
import MonthlyRetentionTotalsByAgentChart from "./charts/MonthlyRetentionTotalsByAgentChart";
import MonthlyRetentionTotalsByAgentChartBar from "./charts/MonthlyRetentionTotalsByAgentChartBar";
import { Link, NavLink } from "react-router-dom";
import DailyResolveTotalsByAgentChart from "./charts/resolve_charts/DailyRetentionTotalsByAgentChart";
import WeeklyRetentionTotalsChart from "./charts/resolve_charts/WeeklyRetentionTotalsChart";
export default function Retention() {
  let numCharts = 5;
  const [isLoading, setIsLoading] = useState(true);
  const [weeklySales, setWeeklySales] = useState([]);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [chartData, setChartData] = useState({});
  const [APChartData, setAPChartData] = useState({});
  const [newChartData, setNewChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [resolvedByDay, setResolvedByDay] = useState();
  const [premiumByDay, setPremiumByDay] = useState();
  const [totalResolved, setTotalResolved] = useState();
  const [totalAP, setTotalAP] = useState();
  const [resolvedType, setResolvedType] = useState("bar");
  const [dailyRetentionResolvesData, setDailyRetentionResolvesData] = useState(
    []
  );
  const [dailyRetentionResolvesLabels, setDailyRetentionResolvesLabels] =
    useState([]);
  const [dailyRetentionAPData, setDailyRetentionAPData] = useState([]);
  const [dailyAP, setDailyAP] = useState([]);
  const [weeklyTotals, setWeeklyTotals] = useState([]);
  const [weeklyByAgent, setWeeklyByAgent] = useState([]);
  const [weeklyByAgentLabels, setWeeklyByAgentLabels] = useState([]);
  const [numChartsLoaded, setNumChartsLoaded] = useState(0);

  const handleResolvedType = (event, newResolvedType) => {
    setResolvedType(newResolvedType);
  };

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("dailyretentionsales/all/")
      .then(function (response) {
        setWeeklySales(response.data);

        let agents = response.data.map(function (e) {
          return e.agent;
        });

        agents = [...new Set(agents)];
        var total_resolve = 0;
        var total_ap = 0;

        agents.map(function (e) {
          response.data.filter(function (item) {
            if (item.agent === e) {
              total_resolve += item.resolved;
              total_ap += item.monthly_premium * 12;
            }
          });
        });

        setTotalResolved(total_resolve);
        setTotalAP(total_ap.toFixed(2));
      })
      .catch(function (error) {
        console.error(error);
      });

    setIsLoading(false);
  }, [setTotalResolved, setTotalAP]);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Typography align="center" variant="h6">
            Total Resolved - {totalResolved}
          </Typography>
          <Typography align="center" variant="h6">
            Total AP - ${totalAP}
          </Typography>
        </Grid> */}
        {/* <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleButtonGroup
            value={resolvedType}
            size="small"
            exclusive
            onChange={handleResolvedType}
          >
            <ToggleButton value="line">
              <ShowChartIcon />
            </ToggleButton>
            <ToggleButton value="bar">
              <BarChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Button
            component={NavLink}
            variant="contained"
            color="primary"
            to="/retention/manage"
          >
            View As List
          </Button>
        </Grid> */}
        <Grid item xs={12}>
          <Button
            component={NavLink}
            variant="contained"
            color="primary"
            to="/retention/entry"
          >
            Add Resolves
          </Button>
        </Grid>
        <Grid item sm={12} xs={12}>
          
          <DailyResolveTotalsByAgentChart
            apiEndpoint="agentresolves/today/"
            title="Self Recorded Resolves (Today)" />
        </Grid>
        <Grid item sm={12} xs={12}>
          <WeeklyRetentionTotalsByAgentChart />
        </Grid>
        <Grid item xs={12}>
          <DailyResolveTotalByAgentChart />
        </Grid>
        <Grid item xs={12}>
          <WeeklyRetentionTotalsChart />
        </Grid>
        {/* <Grid item xs={12}>
          {resolvedType === "line" ? (
            <MonthlyRetentionTotalsByAgentChart />
          ) : (
            <MonthlyRetentionTotalsByAgentChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <MonthlyResolveTotalsChart />
          ) : (
            <MonthlyResolveTotalsChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <WeeklyRetentionTotalsByAgentChart />
          ) : (
            <WeeklyRetentionTotalsByAgentChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <WeeklyResolveTotalsChart />
          ) : (
            <WeeklyResolveTotalsChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <DailyResolveTotalsChart />
          ) : (
            <DailyResolveTotalsChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <DailyResolveAPChart />
          ) : (
            <DailyResolveAPChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <DailyResolveAPByAgentChart />
          ) : (
            <DailyResolveAPByAgentChartBar />
          )}
        </Grid>
        <Grid item xs={12}>
          {resolvedType === "line" ? (
            <DailyResolveTotalByAgentChart />
          ) : (
            <DailyResolveTotalByAgentChartBar />
          )}
        </Grid> */}
      </Grid>
    </Container>
  );
}
