import {
  CircularProgress,
  Container,
  Grid,
  LinearProgress,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";

export default function FRBA() {
  const apiKey = "jONG9B_V8DjXhL510dhfCGv5Tt3pSq_lmqGi6_trnZIBF4Ur3h3jdQ";
  const baseURL = 'https://info@frba.org:'+apiKey+'@donorbox.org/';
  const [isLoading, setIsLoading] = useState(true);



  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return <Container sx={{ mt: 4 }}></Container>;
}
