import {
  Alert,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Component } from "react";
import axiosInstance from "../axiosApi";
import { useHistory } from "react-router";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errorHandle: {
        open: false,
        message: null,
        severity: null,
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.queryParams = new URLSearchParams(window.location.search);
    this.justRegistered = this.queryParams.get("reg");

  }


  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      errorHandle: {
        open: false,
      },
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    axiosInstance
      .post("/token/", {
        email: this.state.email,
        password: this.state.password,
      })
      .then((result) => {
        console.log(result.status);
        console.log(result.data);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        localStorage.removeItem('user_status');

        const tokenParts = JSON.parse(atob(result.data.refresh.split(".")[1]));
        axiosInstance.defaults.headers["Authorization"] =
          "JWT " + result.data.access;
        localStorage.setItem("access_token", result.data.access);
        localStorage.setItem("refresh_token", result.data.refresh);
        localStorage.setItem("username", tokenParts.name);
        localStorage.setItem("user_id", tokenParts.user_id);
        localStorage.setItem("user_status", tokenParts.status);
        if (result.status === 200) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log("there was an error - here");
        console.log(error)
        this.setState({
          errorHandle: {
            open: true,
            message: "Login failed",
            severity: "error",
          },
        });
      });
  }

  render() {
    return (
      <Container maxWidth="sm" sx={{ mt: 2 }}>
        <form onSubmit={this.handleSubmit}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            flexDirection="column"
            sx={{ minHeight: "70vh" }}
          >
            {this.justRegistered &&
            <Grid item>
            <Typography textAlign={"center"}>
             Registration Success!
            </Typography>
              <Typography textAlign={"center"}>
                Login using the email and password that you just set.
              </Typography>
            </Grid>
            }
            <Grid item>
              <TextField
                required
                id="email"
                name="email"
                label="Email Address"
                value={this.state.email}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id="password"
                label="Password"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={this.handleSubmit}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={this.state.errorHandle.open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.errorHandle.severity}
            sx={{ width: "100%" }}
          >
            {this.state.errorHandle.message}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default Login;
