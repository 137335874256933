import {
  Avatar,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import axiosInstance from "../axiosApi";
import React from "react";
import { Dialog } from "@mui/material";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { Grid } from "@mui/material";
import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import SmallMapComponent from "./SmallMapComponent";
import { Chip } from "@mui/material";
import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { LinearProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function Counties() {
  const [open, setOpen] = React.useState(false);
  const [territoryData, setTerritoryData] = React.useState([]);
  const [smallMapData, setSmallMapData] = React.useState([]);
  const [territoryList, setTerritoryList] = React.useState([]);
  const [countyList, setCountyList] = React.useState([]);
  const [openTerritories, setOpenTerritories] = React.useState(0);
  const [managedTerritories, setManagedTerritories] = React.useState(0);
  const history = useHistory();
  const territory_to_show = useParams().id;
  const [isLoading, setIsLoading] = useState(true);

  const returnAssignedUser = (p) => {
    if (typeof p.assigned_user[0] === "undefined") {
      return "Not Assigned";
    } else {
      return p.assigned_user[0].name;
    }
  };
  useEffect(() => {
    axiosInstance.get("tlist").then((result) => {
      setTerritoryList(result.data);
      setIsLoading(false);
      var test_rows = [];
      console.log(result.data);
      result.data.map((item) => {
        item.counties.map((county) => {
          let to_push = {
            territory: item.territory_name,
            county: county.name,
            id: county.fips_code,
            population: county.population,
            assigned_user: returnAssignedUser(item),
            state: county.state,
          };

          test_rows.push(to_push);
        });
      });
      setCountyList(test_rows);
    });
  }, [setTerritoryList]);

  const columns = [
    { field: "territory", headerName: "Territory", flex: 1 },
    { field: "county", headerName: "County", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "assigned_user", headerName: "User", flex: 1 },
    { field: "population", headerName: "Population", flex: 1 },
  ];

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <div className="NotYetDeveloped">
        <Typography variant="h6" mb={2} pb={0}>
          County List
        </Typography>
        <DataGridPro
          autoHeight
          density={"compact"}
          columns={columns}
          rows={countyList}
          components={{ Toolbar: EditToolbar }}
        />
      </div>
    </Container>
  );
}

export default Counties;
