import React, { Component, useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import axios from "axios";
import _ from "lodash";

export default function CancelSaveChart(props) {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartType, setChartType] = useState(props.type);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Cancel Saves",
      },
    },
    cutout: "50%",
  });
  const [state, setState] = React.useState();
  const forceUpdate = () => {
    if (typeof myChart !== "undefined") myChart.destroy();
  };

  const calltoolsAxiosInstance = axios.create({
    baseURL: "https://east-1.calltools.io/api/",
    timeout: 30000,
    headers: {
      Authorization: "Token 5d5f0948893af9149f08ec03613b5040d72b8f5d",
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  }

  async function ExecuteRequest(url, data) {
    // As this is a recursive function, we need to be able to pass it the prevous data. Here we either used the passed in data, or we create a new objet to hold our data.
    data = data || {};
    await calltoolsAxiosInstance.get(url).then((response) => {
      // We merge the returned data with the existing data
      _.mergeWith(data, response.data, customizer);
      // We check if there is more paginated data to be obtained
      if (response.data.next != null) {
        // If nextPageUrl is not null, we have more data to grab
        return ExecuteRequest(response.data.next, data);
      }
    });
    return data;
  }

  useEffect(() => {
    ExecuteRequest("contacttagevents/?tag_id=91165").then((data) => {
      var results = {};
      var dataset = [];
      var date;
      var labels = [];
      var agents = [];
      var final_dataset = [];

      var user_objects = {
        "4b3aa865-e40f-4053-b2bb-c94629343ea2": {
            "first_name": "Admin",
            "last_name": "Account",
            "full_name": "Admin Account",
        },
        "e04418ef-3cd6-4c66-91f6-f09791a0f865": {
            "first_name": "Adriana",
            "last_name": "Pantoja",
            "full_name": "Adriana Pantoja",
            "bg_color": "rgba(6, 141, 157)",
        },
        "6ef2fccb-6962-4e22-8daf-634a7208b3be": {
            "first_name": "Ciarra",
            "last_name": "Pass",
            "full_name": "Ciarra Pass",
            "bg_color": "rgba(83, 89, 154)",
        },
        "032c1115-f9c3-442d-9d6e-0f34e8939a20": {
            "first_name": "Dominque",
            "last_name": "Overstreet",
            "full_name": "Dominque Overstreet",
            "bg_color": "rgba(109, 157, 197)",
        },
        "6de28aa9-d118-4cb9-a9fa-c85ef13ea85d": {
            "first_name": "Erin",
            "last_name": "Edwards",
            "full_name": "Erin Edwards",
        },
        "bb1240ed-dcfe-4793-b82b-334c92494173": {
            "first_name": "Jacobee",
            "last_name": "Satterfield",
            "full_name": "Jacobee Satterfield",
            "bg_color": "rgba(128, 222, 217)",
        },
        "643e1172-3343-4555-9ad6-5b8fa47d2c01": {
            "first_name": "Latoya",
            "last_name": "Williams",
            "full_name": "Latoya Williams",
            "bg_color": "rgba(80, 132, 156)",
        },
        "ec890471-da4d-4689-82f6-1f6031ece703": {
            "first_name": "LJ",
            "last_name": "Abrams",
            "full_name": "LJ Abrams",
        },
        "89170429-2ba5-40d1-bcf4-298913f5006c": {
            "first_name": "Robin",
            "last_name": "Cochrane",
            "full_name": "Robin Cochrane",
            "bg_color": "rgba(174, 236, 239)",
        },
        "81f58dd8-8f75-4cf2-a1ff-81580ef7f239": {
            "first_name": "Shayla",
            "last_name": "Price",
            "full_name": "Shayla Price",
        }
    }

      var background_colors = [
        "rgba(50,160,230)",
        "rgba(70,180,250)",
        "rgba(90,200,255)",
      ]

      for (var i = 0; i < data.results.length; i++) {
        if (agents.indexOf(data.results[i].app_user) === -1) {
          agents.push(data.results[i].app_user);
        }
      }

      for (var i = 0; i < data.results.length; i++) {
        // get the date
        date = [
          new Date(data.results[i].created_on).getFullYear(),
          new Date(data.results[i].created_on).getMonth() + 1,
          new Date(data.results[i].created_on).getDate(),
        ].join("-");
        results[date] = results[date] || 0;
        results[date]++;
      }
      for (var i in results) {
        if (results.hasOwnProperty(i)) {
          labels.push(i);
        }
      }

      for (var a = 0; a < agents.length; a++) {
        var dataset = [];
        var results = [];
        var temp_labels = [];
        for (var i = 0; i < data.results.length; i++) {
          if (data.results[i].app_user === agents[a] && data.results[i].app_user != null) {
            // get the date
            date = [
              new Date(data.results[i].created_on).getFullYear(),
              new Date(data.results[i].created_on).getMonth() + 1,
              new Date(data.results[i].created_on).getDate(),
            ].join("-");
            results[date] = results[date] || 0;
            results[date]++;
          }
        }
        for (var c in results) {
          if (results.hasOwnProperty(c)) {
            temp_labels.push(c);
            dataset.push(results[c]);
          }
        }
        labels.map(function (d) {
          if (temp_labels.indexOf(d) === -1) {
            dataset.splice(labels.indexOf(d), 0, null);
          }
        });

        if(agents[a] !== null) {
        final_dataset.push({
          label: user_objects[agents[a]].full_name,
          data: dataset.reverse(),
          backgroundColor: user_objects[agents[a]].bg_color,
        });}
      }

      myChartRef = chartRef.current.getContext("2d");
      data = {
        labels: labels.reverse(),
        datasets: final_dataset,
      };
      myChart = new Chart(myChartRef, {
        type: "bar",
        data: data,
        options: chartOptions,
      });
    });
  }, [state]);
  return (
    <div>
      <canvas id="CancelSaveChart" ref={chartRef} />
    </div>
  );
}
