import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../../axiosApi";
import { Chart } from "chart.js";
import { backgroundColors } from "./colorThemes.js";

export default function DailyResolveTotalsByAgentChart({ apiEndpoint, title }) {
  const chartRef = useRef();
  const [myChart, setMyChart] = useState(null);
  const [chartData, setChartData] = useState({});
  const [chartOptions] = useState({
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(apiEndpoint);
      const backgroundColorOrder = response.data.map(e => backgroundColors[e.agent].backgroundColor);
      const borderColorOrder = response.data.map(e => backgroundColors[e.agent].borderColor);

      setChartData({
        labels: response.data.map((item) => item.agent),
        datasets: [{
          label: "Resolves",
          data: response.data.map((item) => item.sum_of_policies),
          backgroundColor: backgroundColorOrder,
          borderColor: borderColorOrder,
          borderWidth: 3,
          lineTension: 0.1,
          borderRadius: 2,
        }],
      });
    } catch (error) {
      console.error(error);
      // Handle error state here, possibly update UI
    }
  };

  useEffect(() => {
    if (chartRef.current && chartData.labels) {
      if (myChart) myChart.destroy();
      const chartInstance = new Chart(chartRef.current.getContext("2d"), {
        type: "bar",
        data: chartData,
        options: chartOptions,
      });
      setMyChart(chartInstance);
    }
  }, [chartData]);

  useEffect(() => {
    fetchData();
  }, []); // Empty array ensures this effect runs only once on mount

  return (
    <div>
      <canvas id="DailyResolveTotalsByAgentChart" ref={chartRef} />
    </div>
  );
}
