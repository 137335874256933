import {
  CircularProgress,
  Collapse,
  Container,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { Chart } from "chart.js";
import React, { Component, useEffect, useRef, useState } from "react";
import axiosInstance from "../axiosApi";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Row(props) {
  const { item } = props;
  const [open, setOpen] = useState(false);


  const handleFullEditClick = (id) => (event) => {
    event.stopPropagation();
    const win = window.open("/view/" + id + "/", "_blank");
    win.focus();
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell><a href="" onClick={handleFullEditClick(item.id)}>{item.first_name + " " + item.last_name}</a></TableCell>
        <TableCell align="center">{item.status}</TableCell>
        <TableCell align="center">
          {item.welcome_date}
        </TableCell>
        <TableCell align="center">
          {item.has_pass ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.has_ica ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.exam_date ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.ein && item.business_entity ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.has_personal ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.has_agency ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.has_eo ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.writing_number ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function OnboardingAdmin() {
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    axiosInstance.get("onboardinglist").then((res) => {
      setUserList(res.data);
      setIsLoading(false);
    });   
    
    axiosInstance.get("traininglist").then((res) => {
      setTrainingList(res.data);
      setIsLoading(false);
    });
  }, [setUserList]);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center" maxWidth="xl">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth="xl">
      <Typography variant="h6" textAlign="center" mb={4}>
        Not Sent To Training
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Candidate</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Welcome Date</TableCell>
              <TableCell align="center">Portal</TableCell>
              <TableCell align="center">ICA</TableCell>
              <TableCell align="center">Exam</TableCell>
              <TableCell align="center">EIN/Entity</TableCell>
              <TableCell align="center">Personal</TableCell>
              <TableCell align="center">Agency</TableCell>
              <TableCell align="center">E&O</TableCell>
              <TableCell align="center">Appointed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList.map(function (item) {
              return <Row key={item.id} item={item} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" textAlign="center" my={4}>
        Sent To Training
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Candidate</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Welcome Date</TableCell>
              <TableCell align="center">Portal</TableCell>
              <TableCell align="center">ICA</TableCell>
              <TableCell align="center">Exam</TableCell>
              <TableCell align="center">EIN/Entity</TableCell>
              <TableCell align="center">Personal</TableCell>
              <TableCell align="center">Agency</TableCell>
              <TableCell align="center">E&O</TableCell>
              <TableCell align="center">Appointed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainingList.map(function (item) {
              if(!item.writing_number) {
               return <Row key={item.id} item={item} />;
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
