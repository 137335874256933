import {
  AlertTitle,
  Avatar,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../axiosApi";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import { usePlacesWidget } from "react-google-autocomplete";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { forwardRef } from "react";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Link from "@mui/material/Link";
import { List, ListItem } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from "@mui/icons-material/Preview";
import DatePicker from "@mui/lab/DatePicker";
//const baseURL = "https://management-portal-api.herokuapp.com/api/";
const baseURL = "https://portalapi.consolidatedassurance.com/api/";
//const baseURL = "http://127.0.0.1:8000/api/";

function ViewUser() {
  let formmattedWelcomeDate = null;
  let formmattedEstimatedLaunchDate = null;
  let formmattedAppointedDate = null;
  let formmattedTrainingLaunchDate = null;
  let formmattedIPCIBDate = null;
  let formmattedGBOneonOneDate = null;
  let formmattedExamDate = null;
  let formattedBirthday = null;
  let formattedLaunchDate = null;
  let formattedTermDate = null;
  const user_id = useParams().id;
  const [userInfo, setUserInfo] = useState([]);
  const [userList, setUserList] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [license, setLicense] = useState(null);
  const [territoryList, setTerritoryList] = useState([]);
  const [errorHandle, setErrorHandle] = useState({
    open: false,
    message: null,
    severity: null,
  });
  const [open, setOpen] = useState(false);
  const [selectedWelcomeDate, setSelectedWelcomeDate] = useState(null);
  const [selectedEstimatedLaunchDate, setEstimatedLaunchDate] = useState(null);
  const [selectedAppointedDate, setSelectedAppointedDate] = useState(null);
  const [selectedIBIPCDate, setSelectedIBIPCDate] = useState(null);
  const [selectedGBOneDate, setSelectedGBOneDate] = useState(null);
  const [selectedTrainingLaunchDate, setSelectedTrainingLaunchDate] = useState(null);
  const [selectedExamDate, setSelectedExamDate] = useState(null);
  const [selectedTermDate, setSelectedTermDate] = useState(null);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(null);
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);
  const [selectedLaunchDate, setSelectedLaunchDate] = useState(null);
  const [postimage, setPostImage] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [userUploads, setUserUploads] = useState([]);
  const [categoryChange, setCategoryChange] = useState(1);
  const [fileUpload, setFileUpload] = useState(null);
  const [buttonName, setButtonName] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorHandle({ ...errorHandle, open: false });
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const initialUserData = Object.freeze({});

  const [formData, setFormData] = useState(initialUserData);

  var usStates = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];

  useEffect(() => {
    axiosInstance.get("notes/all/" + user_id).then((result) => {
      setNotesList(result.data);
    });
  }, [setNotesList]);

  useEffect(() => {
    axiosInstance.get("documents/all/" + user_id).then((result) => {
      setUserUploads(result.data);
    });
  }, [setUserUploads]);

  useEffect(() => {
    axiosInstance.get("tlist").then((result) => {
      setTerritoryList(result.data);
    });
  }, [setTerritoryList]);

  useEffect(() => {
    axiosInstance.get(user_id).then((res) => {
      setUserInfo(res.data);
      if (res.data.territory) {
        res.data.territory = res.data.territory.id;
      }

      if (res.data.welcome_date) {
        formmattedWelcomeDate = moment(res.data.welcome_date).format(
          "yyyy-MM-DD"
        );
        formmattedWelcomeDate = new Date(formmattedWelcomeDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedWelcomeDate = null;
      }
      setSelectedWelcomeDate(formmattedWelcomeDate);


      if (res.data.estimated_launch) {
        formmattedEstimatedLaunchDate = moment(res.data.estimated_launch).format(
          "yyyy-MM-DD"
        );
        formmattedEstimatedLaunchDate = new Date(formmattedEstimatedLaunchDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedEstimatedLaunchDate = null;
      }
      setEstimatedLaunchDate(formmattedEstimatedLaunchDate);

      if (res.data.training_kick_off_date) {
        formmattedTrainingLaunchDate = moment(res.data.training_kick_off_date).format(
          "yyyy-MM-DD"
        );
        formmattedTrainingLaunchDate = new Date(formmattedTrainingLaunchDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedTrainingLaunchDate = null;
      }
      setSelectedTrainingLaunchDate(formmattedTrainingLaunchDate);


      if (res.data.ipc_ib_date) {
        formmattedIPCIBDate = moment(res.data.ipc_ib_date).format(
          "yyyy-MM-DD"
        );
        formmattedIPCIBDate = new Date(formmattedIPCIBDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedIPCIBDate = null;
      }
      setSelectedIBIPCDate(formmattedIPCIBDate);

      if (res.data.gb_one_on_one_date) {
        formmattedGBOneonOneDate = moment(res.data.gb_one_on_one_date).format(
          "yyyy-MM-DD"
        );
        formmattedGBOneonOneDate = new Date(formmattedGBOneonOneDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedGBOneonOneDate = null;
      }
      setSelectedGBOneDate(formmattedGBOneonOneDate);

      if (res.data.appointed_date) {
        formmattedAppointedDate = moment(res.data.appointed_date).format(
          "yyyy-MM-DD"
        );
        formmattedAppointedDate = new Date(formmattedAppointedDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedAppointedDate = null;
      }
      setSelectedAppointedDate(formmattedAppointedDate);

      if (res.data.term_date) {
        formattedTermDate = moment(res.data.term_date).format("yyyy-MM-DD");
        formattedTermDate = new Date(formattedTermDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formattedTermDate = null;
      }
      setSelectedTermDate(formattedTermDate);

      if (res.data.exam_date) {
        formmattedExamDate = moment(res.data.exam_date).format("yyyy-MM-DD");
        formmattedExamDate = new Date(formmattedExamDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedExamDate = null;
      }
      setSelectedExamDate(formmattedExamDate);

      if (res.data.birthday) {
        formattedBirthday = moment(res.data.birthday).format("yyyy-MM-DD");
        formattedBirthday = new Date(formattedBirthday).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formattedBirthday = null;
      }
      setSelectedBirthDate(formattedBirthday);

      if (res.data.launch_date) {
        formattedLaunchDate = moment(res.data.launch_date).format("yyyy-MM-DD");
        formattedLaunchDate = new Date(formattedLaunchDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formattedLaunchDate = null;
      }
      setSelectedLaunchDate(formattedLaunchDate);
      setFormData(res.data);
    });
  }, [setUserInfo]);

  const handleWelcomeDateChange = (date) => {
    setSelectedWelcomeDate(date);
    setFormData((formData) => ({
      ...formData,
      welcome_date: date,
    }));
  };

  const handleAppointedDateChange = (date) => {
    setSelectedAppointedDate(date);
    setFormData((formData) => ({
      ...formData,
      appointed_date: date,
    }));
  };

  const handleIBIPCDateChange = (date) => {
    setSelectedIBIPCDate(date);
    setFormData((formData) => ({
      ...formData,
      ipc_ib_date: date,
    }));
  };

  const handleGBOneChange = (date) => {
    setSelectedGBOneDate(date);
    setFormData((formData) => ({
      ...formData,
      gb_one_on_one_date: date,
    }));
  };

  const handleTrainingLaunchDateChange = (date) => {
    setSelectedTrainingLaunchDate(date);
    setFormData((formData) => ({
      ...formData,
      training_kick_off_date: date,
    }));
  };

  const handleFileChange = (e) => {
    setFileUpload(e.target.files);
    setButtonName(e.target.files[0].name);
  };

  const handleExamDateChange = (date) => {
    setSelectedExamDate(date);
    setFormData((formData) => ({
      ...formData,
      exam_date: date,
    }));
  };

  const handleExpirationDateChange = (date) => {
    setSelectedExpirationDate(date);
  };

  const handleBirthDateChange = (date) => {
    setSelectedBirthDate(date);
    setFormData((formData) => ({
      ...formData,
      birthday: date,
    }));
  };

  const handleLaunchDateChange = (date) => {
    setSelectedLaunchDate(date);
    setFormData((formData) => ({
      ...formData,
      launch_date: date,
    }));
  };

  const handleEstimatedLaunchDateChange = (date) => {
    setEstimatedLaunchDate(date);
    setFormData((formData) => ({
      ...formData,
      estimated_launch: date,
    }));
  };
  const handleTermDateChange = (date) => {
    setSelectedTermDate(date);
    setFormData((formData) => ({
      ...formData,
      term_date: date,
    }));
  };
  const handleChange = (e) => {
    if ([e.target.name] == "image") {
      setPostImage({
        image: e.target.files,
      });
    } else {
      setFormData((formData) => ({
        ...formData,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleCategoryChange = (e) => {
    setCategoryChange(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.checked,
    }));
  };

  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyCskE87HFoEDuYr_GP6IYQcLc-Efc2Uc-Q",
    onPlaceSelected: (place) => {
      setFormData((formData) => ({
        ...formData,
        address: place.formatted_address,
      }));
    },
    inputAutocompleteValue: "country",
    options: {
      types: "address",
    },
  });

  const handleNoteChange = (e) => {
    setNewNote(e.target.value);
  };

  const addNote = (e) => {
    e.preventDefault();
    axiosInstance
      .post("notes/", {
        note_to: user_id,
        note_from: localStorage.getItem("user_id"),
        note: newNote,
      })
      .then((res) => {
        axiosInstance.get("notes/all/" + user_id).then((result) => {
          setNotesList(result.data);
        });
        setNewNote("");
        setErrorHandle({
          open: true,
          message: "Note Added",
          severity: "success",
        });
      });
  };

  const deleteDocument = (e, id) => {
    e.preventDefault();
    setErrorHandle({
      open: true,
      message: "Deleting document...",
      severity: "info",
    });
    axiosInstance.delete("documents/" + id + "/").then((res) => {
      axiosInstance.get("documents/all/" + user_id).then((result) => {
        setUserUploads(result.data);
        setErrorHandle({
          open: true,
          message: "Document deleted",
          severity: "success",
        });
      });
    });
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (fileUpload === null) {
      setErrorHandle({
        open: true,
        message: "You didn't select a document!",
        severity: "error",
      });
    } else {
      const config = {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
        },
      };
      const URL = baseURL + "documents/";
      let uploadData = new FormData();
      uploadData.append("file", fileUpload[0]);
      uploadData.append("uploaded_to", user_id);
      uploadData.append("uploaded_from", localStorage.getItem("user_id"));
      uploadData.append("category", categoryChange);
      let formmattedExpirationDate = null;
      if (selectedExpirationDate != null) {
        formmattedExpirationDate = moment(selectedExpirationDate).format(
          "yyyy-MM-DD"
        );
        uploadData.append("expiration_date", formmattedExpirationDate);
      }

      setErrorHandle({
        open: true,
        message: "Uploading document...",
        severity: "info",
      });
      axios
        .post(URL, uploadData, config)
        .then((res) => {
          setButtonName(null);
          setErrorHandle({
            open: true,
            message: "Document Added",
            severity: "success",
          });

          axiosInstance.get("documents/all/" + user_id).then((result) => {
            setUserUploads(result.data);
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: localStorage.getItem("access_token")
          ? "JWT " + localStorage.getItem("access_token")
          : null,
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    };
    const URL = baseURL + user_id + "/";

    let postData = new FormData();
    // check if file was uploaded

    if (postimage) {
      postData.append("license", postimage.image[0]);
    }
    if (!license) {
      delete formData.license;
    }

    for (var key in formData) {
      let temp = formData[key];
      if (temp) {
        postData.append(key, temp);
      }
    }
    if (formData.welcome_date) {
      formmattedWelcomeDate = moment(formData.welcome_date).format(
        "yyyy-MM-DD"
      );
      postData.append("welcome_date", formmattedWelcomeDate);
    } else {
      postData.append("welcome_date", "");
    }
    if (formData.estimated_launch) {
      formmattedEstimatedLaunchDate = moment(formData.estimated_launch).format(
        "yyyy-MM-DD"
      );
      postData.append("estimated_launch", formmattedEstimatedLaunchDate);
    } else {
      postData.append("estimated_launch", "");
    }
    if (formData.appointed_date) {
      formmattedAppointedDate = moment(formData.appointed_date).format(
        "yyyy-MM-DD"
      );
      postData.append("appointed_date", formmattedAppointedDate);
    } else {
      postData.append("appointed_date", "");
    }
    if (formData.training_kick_off_date) {
      formmattedTrainingLaunchDate = moment(formData.training_kick_off_date).format(
        "yyyy-MM-DD"
      );
      postData.append("training_kick_off_date", formmattedTrainingLaunchDate);
    } else {
      postData.append("training_kick_off_date", "");
    }
    if (formData.ipc_ib_date) {
      formmattedIPCIBDate = moment(formData.ipc_ib_date).format(
        "yyyy-MM-DD"
      );
      postData.append("ipc_ib_date", formmattedIPCIBDate);
    } else {
      postData.append("ipc_ib_date", "");
    }
    if (formData.gb_one_on_one_date) {
      formmattedGBOneonOneDate = moment(formData.gb_one_on_one_date).format(
        "yyyy-MM-DD"
      );
      postData.append("gb_one_on_one_date", formmattedGBOneonOneDate);
    } else {
      postData.append("gb_one_on_one_date", "");
    }
    if (formData.exam_date) {
      formmattedExamDate = moment(formData.exam_date).format("yyyy-MM-DD");
      postData.append("exam_date", formmattedExamDate);
    } else {
      postData.append("exam_date", "");
    }
    if (formData.term_date) {
      formattedTermDate = moment(formData.term_date).format("yyyy-MM-DD");
      postData.append("term_date", formattedTermDate);
    } else {
      postData.append("term_date", "");
    }
    if (formData.birthday) {
      formmattedExamDate = moment(formData.birthday).format("yyyy-MM-DD");
      postData.append("birthday", formmattedExamDate);
    } else {
      postData.append("birthday", "");
    }
    if (formData.launch_date) {
      formattedLaunchDate = moment(formData.launch_date).format("yyyy-MM-DD");
      postData.append("launch_date", formattedLaunchDate);
    } else {
      postData.append("launch_date", "");
    }
    if (formData.territory === 0) {
      postData.append("territory", "");
    }
    if (formData.resident_state === 0) {
      postData.append("resident_state", "");
    }

    if (!formData.ein) {
      postData.append("ein", "");
    }
    if (!formData.npn) {
      postData.append("npn", "");
    }
    if (!formData.agency_npn) {
      postData.append("agency_npn", "");
    }

    postData.append("approved_to_pay", formData.approved_to_pay);
    postData.append("awaiting_appointment", formData.awaiting_appointment);
    postData.append("training_kick_off", formData.training_kick_off);
    postData.append("ipc", formData.ipc);
    postData.append("ib", formData.ib);
    postData.append("ipc_ib_certified", formData.ipc_ib_certified);
    postData.append("gb", formData.gb);
    postData.append("one_on_one", formData.one_on_one);
    postData.append(
      "gb_one_on_one_certified",
      formData.gb_one_on_one_certified
    );
    postData.append("ready_for_launch", formData.ready_for_launch);
    postData.append("rrp", formData.rrp);
    postData.append("launched", formData.launched);

    axios
      .put(URL, postData, config)
      .then((res) => {
        if (res.status == 200) {
          setErrorHandle({
            open: true,
            message: "User Updated",
            severity: "success",
          });
          if (res.data.license) {
            setFormData((formData) => ({
              ...formData,
              license: res.data.license,
            }));
            setPostImage(null);
          }
        }
      })
      .catch(function (error) {
        setErrorHandle({
          open: true,
          message: "Error updating user",
          severity: "error",
        });
      });
  };

  return (
    <Container maxWidth="lg">
      <Typography my={4}>
      <Alert severity="error" variant="outlined">
        <AlertTitle>Read Below</AlertTitle>
        <p>Certain actions on this page will send automatic emails. They'll be highlighted in red when selected or edited.</p>
      
        <p><strong>Emails are sent when you save changes. If you accidently click a toggle or fill one of those fields out, just refresh/close the page without saving their profile.</strong></p>
      </Alert></Typography>
      <Typography variant="h4" my={4} pb={0} textAlign="center">
        {userInfo.first_name} {userInfo.last_name}
      </Typography>
      <Grid container spacing={1}>
        <Grid
          container
          item
          spacing={2}
          mb={4}
          xs={8}
          alignContent="flex-start"
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="first_name"
              name="first_name"
              label="First Name"
              value={formData.first_name || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="last_name"
              name="last_name"
              label="Last Name"
              value={formData.last_name || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="phone_number"
              name="phone_number"
              label="Phone Number"
              value={formData.phone_number || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="voxer"
              name="voxer"
              label="Voxer"
              value={formData.voxer || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="email"
              name="email"
              label="Work Email"
              value={formData.email || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="personal_email"
              name="personal_email"
              label="Personal Email"
              value={formData.personal_email || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              id="address"
              name="address"
              label="Address"
              fullWidth
              onChange={handleChange}
              value={formData.address}
              inputRef={materialRef}
            />
          </Grid>

          <Grid item xs={6} textAlign="left">
            <FormControl fullWidth>
              <InputLabel id="territory-select-label">Territory</InputLabel>
              <Select
                labelId="territory-select-label"
                id="territory"
                label="Territory"
                onChange={handleChange}
                value={formData.territory || 0}
                variant="outlined"
                name="territory"
              >
                <MenuItem value={0}>No Territory Assigned</MenuItem>
                {territoryList.map((item) => (
                  <MenuItem value={item.id}>{item.territory_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} textAlign="left">
            <FormControl fullWidth>
              <InputLabel id="resident-state-select-label">
                Resident State
              </InputLabel>
              <Select
                labelId="resident-state-select-label"
                id="resident-state"
                label="resident-state"
                onChange={handleChange}
                value={formData.resident_state || 0}
                variant="outlined"
                name="resident_state"
              >
                <MenuItem value={0}>No Resident Assigned</MenuItem>
                {usStates.map((item) => (
                  <MenuItem value={item.abbreviation}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="business_entity"
              name="business_entity"
              label="Business Entity"
              value={formData.business_entity || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="ein"
              name="ein"
              label="EIN"
              value={formData.ein || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="npn"
              name="npn"
              label="Individual NPN"
              value={formData.npn || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="agency_npn"
              name="agency_npn"
              label="Agency NPN"
              value={formData.agency_npn || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="writing_number"
              name="writing_number"
              label="Writing Number"
              color="error"
              value={formData.writing_number || ""}
              onChange={handleChange}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Birthday"
                inputFormat="MM/dd/yyyy"
                value={selectedBirthDate}
                onChange={handleBirthDateChange}
                name="birthday"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Welcome Date"
                inputFormat="MM/dd/yyyy"
                value={selectedWelcomeDate}
                onChange={handleWelcomeDateChange}
                name="welcome_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Exam Date"
                inputFormat="MM/dd/yyyy"
                value={selectedExamDate}
                onChange={handleExamDateChange}
                name="exam_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Appointed Date"
                inputFormat="MM/dd/yyyy"
                value={selectedAppointedDate}
                onChange={handleAppointedDateChange}
                name="appointed_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Pre-Launch Date"
                inputFormat="MM/dd/yyyy"
                value={selectedTrainingLaunchDate}
                onChange={handleTrainingLaunchDateChange}
                name="training_kick_off_date"
                renderInput={(params) => <TextField  color="error" fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="IB/IPC Certification Date"
                inputFormat="MM/dd/yyyy"
                value={selectedIBIPCDate}
                onChange={handleIBIPCDateChange}
                name="ipc_ib_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="GB/One-on-One Certification Date"
                inputFormat="MM/dd/yyyy"
                value={selectedGBOneDate}
                onChange={handleGBOneChange}
                name="gb_one_on_one_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Estimated Launch Date"
                inputFormat="MM/dd/yyyy"
                value={selectedEstimatedLaunchDate}
                onChange={handleEstimatedLaunchDateChange}
                name="launch_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Field Launch Date"
                inputFormat="MM/dd/yyyy"
                value={selectedLaunchDate}
                onChange={handleLaunchDateChange}
                name="launch_date"
                renderInput={(params) => <TextField color="error" fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6} align="left">
              <DatePicker
                label="Termination Date"
                inputFormat="MM/dd/yyyy"
                value={selectedTermDate}
                onChange={handleTermDateChange}
                name="term_date"
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                required
                id="source"
                name="source"
                label="Source"
                value={formData.source || ""}
                onChange={handleChange}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={4}
          spacing={2}
          mb={4}
          alignContent="flex-start"
        >
          <Grid item xs={12} align="right">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="awaiting_appointment"
                    id="awaiting_appointment"
                    checked={formData.awaiting_appointment}
                    color="primary"
                    onChange={handleCheckboxChange}
                    labelPlacement="start"
                  />
                }
                label="Submitted for Appointment"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="approved_to_pay"
                    id="approved_to_pay"
                    checked={formData.approved_to_pay}
                    onChange={handleCheckboxChange}
                    labelPlacement="start"
                    color="error"
                  />
                }
                label="Onboarding Completed"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="rrp"
                    id="rrp"
                    checked={formData.rrp}
                    color="primary"
                    onChange={handleCheckboxChange}
                    labelPlacement="start"
                  />
                }
                label="RRP Setup Completed"
              />
              <FormControlLabel
                control={
                  <Switch
                    name="training_kick_off"
                    id="training_kick_off"
                    checked={formData.training_kick_off}
                    onChange={handleCheckboxChange}
                    labelPlacement="start"
                    color="primary"
                  />
                }
                label="Training Kick Off"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.ipc}
                    onChange={handleCheckboxChange}
                    name="ipc"
                    id="ipc"
                  />
                }
                label="IPC"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.ib}
                    onChange={handleCheckboxChange}
                    name="ib"
                  />
                }
                label="IB"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.ipc_ib_certified}
                    onChange={handleCheckboxChange}
                    name="ipc_ib_certified"
                  />
                }
                label="IPC/IB Certified"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.gb}
                    onChange={handleCheckboxChange}
                    name="gb"
                  />
                }
                label="GB"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.one_on_one}
                    onChange={handleCheckboxChange}
                    name="one_on_one"
                  />
                }
                label={"One on One"}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.gb_one_on_one_certified}
                    onChange={handleCheckboxChange}
                    name="gb_one_on_one_certified"
                  />
                }
                label="GB/One on One Certified"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.ready_for_launch}
                    onChange={handleCheckboxChange}
                    name="ready_for_launch"
                  />
                }
                label="Ready for Launch"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.launched}
                    onChange={handleCheckboxChange}
                    name="launched"
                    color="primary"
                  />
                }
                label="Launched"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={12} align="right">
            <TextField
              id="new_note"
              label="Add Note"
              placeholder=""
              value={newNote}
              onChange={handleNoteChange}
              multiline
              minRows={4}
              fullWidth
              sx={{ background: "#f8f8f8" }}
            />
            <Button
              onClick={addNote}
              sx={{ my: 2 }}
              variant="contained"
              color="primary"
              fullWidth
            >
              Add Note
            </Button>
            <List
              sx={{
                width: "100%",
                maxHeight: "50vh",
                overflowY: "scroll",
                my: 0,
                py: 0,
                background: "#fff",
              }}
              dense="true"
            >
              {notesList.map((item) => (
                <ListItem
                  sx={{
                    m: 0,
                    p: 0,
                    mb: 2,
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    background: "#f8f8f8",
                  }}
                >
                  <ListItemText
                    sx={{ m: 0, p: 1 }}
                    primary={item.note}
                    secondary={
                      moment(item.date)
                        .format("MM/DD/yyyy h:mma")
                        .toLocaleString("en-US", { timeZone: "EST" }) +
                      " - " +
                      item.note_from
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          mb={4}
          alignContent="flex-start"
        >
          <Grid container item xs={4} alignContent="flex-start">
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryChange}
                  label="Category"
                  onChange={handleCategoryChange}
                >
                  <MenuItem value={1}>ICA</MenuItem>
                  <MenuItem value={2}>Personal License</MenuItem>
                  <MenuItem value={3}>Agency License</MenuItem>
                  <MenuItem value={4}>E&O</MenuItem>
                  <MenuItem value={5}>AFBA Contract</MenuItem>
                  <MenuItem value={6}>Other (In-House Only)</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} align="left" mt={1}>
                <MobileDatePicker
                  label="Expiration Date"
                  inputFormat="MM/dd/yyyy"
                  value={selectedExpirationDate}
                  onChange={handleExpirationDateChange}
                  name="exam_date"
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} mt={1}>
              <span>{buttonName}</span>
            </Grid>
            <Grid item xs={12} mt={1}>
              <input
                id="file-upload"
                onChange={handleFileChange}
                name="file-upload"
                type="file"
                style={{ display: "none" }}
              />
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  startIcon={<CloudDownloadIcon />}
                  fullWidth
                  size="large"
                >
                  Select File
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Button
                variant="contained"
                color="primary"
                component="span"
                fullWidth
                onClick={handleFileSubmit}
                startIcon={<CloudUploadIcon />}
                size="large"
              >
                Upload FIle
              </Button>
            </Grid>
          </Grid>
          <Grid container item xs={8}>
            <Grid item xs={12} align="left" direction="row">
              {userUploads.map((item) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {item.category} -{" "}
                  {moment(item.date_added)
                    .format("MM/DD/yyyy h:mma")
                    .toLocaleString("en-US", { timeZone: "EST" })}{" "}
                  <Link href={item.file} target="_blank">
                    <PreviewIcon align="flexCenter" sx={{ display: "block" }} />
                  </Link>
                  {"Expires - " + (item.expiration_date || "N/A")}
                  <Link onClick={(e) => deleteDocument(e, item.id)} href="">
                    <DeleteForeverIcon
                      color="error"
                      align="flexCenter"
                      sx={{ display: "block" }}
                    />
                  </Link>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={errorHandle.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={errorHandle.severity}
          sx={{ width: "100%" }}
        >
          {errorHandle.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ViewUser;
