import React, { useState, useEffect, forwardRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import axiosInstance from "../axiosApi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Select, Tab, Tabs } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
//const baseURL = "https://management-portal-api.herokuapp.com/api/";
const baseURL = "https://portalapi.consolidatedassurance.com/api/";
//const baseURL = "http://127.0.0.1:8000/api/";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdditionalInfo() {
  // consts
  let formmattedExamDate = null;
  let formattedBirthday = null;
  const history = useHistory();
  const user = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  const [tabValue, setTabValue] = useState(0);
  const [fileUpload, setFileUpload] = useState(null);
  const [buttonName, setButtonName] = useState("");
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(null);
  const [userUploads, setUserUploads] = useState([]);
  const [categoryChange, setCategoryChange] = useState(2);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // not logged in
  if (!user_id) {
    history.push("/login");
  }

  useEffect(() => {
    axiosInstance.get("documents/all/" + user_id).then((result) => {
      result.data = result.data.filter(function(item) { 
        return item.category !== 'Other (In-House Only)'; 
      });
      setUserUploads(result.data);
    });
  }, [setUserUploads]);
  // init for address field
  const { ref: materialRef } = usePlacesWidget({
    apiKey: "AIzaSyCskE87HFoEDuYr_GP6IYQcLc-Efc2Uc-Q",
    onPlaceSelected: (place) => {
      updateFormData((formData) => ({
        ...formData,
        address: place.formatted_address,
      }));
    },
    inputAutocompleteValue: "country",
    options: {
      types: "address",
    },
  });

  const handleFileChange = (e) => {
    setFileUpload(e.target.files);
    setButtonName(e.target.files[0].name);
  };

  const handleExpirationDateChange = (date) => {
    setSelectedExpirationDate(date);
  };

  const handleCategoryChange = (e) => {
    setCategoryChange(e.target.value);
  };
  // init for error/success message
  const [errorHandle, setErrorHandle] = useState({
    open: false,
    message: null,
    severity: null,
  });
  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (fileUpload === null) {
      setErrorHandle({
        open: true,
        message: "You didn't select a document!",
        severity: "error",
      });
    } else {
      const config = {
        headers: {
          Authorization: localStorage.getItem("access_token")
            ? "JWT " + localStorage.getItem("access_token")
            : null,
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
        },
      };
      const URL = baseURL + "documents/";
      let uploadData = new FormData();
      uploadData.append("file", fileUpload[0]);
      uploadData.append("uploaded_to", user_id);
      uploadData.append("uploaded_from", localStorage.getItem("user_id"));
      uploadData.append("category", categoryChange);
      let formmattedExpirationDate = null;
      if (selectedExpirationDate != null) {
        formmattedExpirationDate = moment(selectedExpirationDate).format(
          "yyyy-MM-DD"
        );
        uploadData.append("expiration_date", formmattedExpirationDate);
      }

      setErrorHandle({
        open: true,
        message: "Uploading document...",
        severity: "info",
      });
      axios
        .post(URL, uploadData, config)
        .then((res) => {
          setButtonName(null);
          setErrorHandle({
            open: true,
            message: "Document Added",
            severity: "success",
          });

          axiosInstance.get("documents/all/" + user_id).then((result) => {
            result.data = result.data.filter(function(item) { 
              return item.category !== 'Other (In-House Only)'; 
            });
            setUserUploads(result.data);
          });
        })
        .catch(function (error) {
          console.log(error.response);
        });
    }
  };
  // close error/success message
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorHandle({ open: false });
  };

  // init form
  const initialFormData = Object.freeze({
    ein: "",
    voxer: "",
    exam_date: "",
    firstName: "",
    lastName: "",
    email: "",
    passedLicense: false,
    submittedAFBA: false,
    submittedICA: false,
    address: "",
  });

  // init states
  const [formData, updateFormData] = useState(initialFormData);
  const [postimage, setPostImage] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedBirthDate, setSelectedBirthDate] = React.useState(null);

  // get user info
  useEffect(() => {
    axiosInstance.get(user_id).then((res) => {
      updateFormData({
        ...formData,
        ["ein"]: res.data.ein,
        ["business_entity"]: res.data.business_entity,
        ["voxer"]: res.data.voxer,
        ["exam_date"]: res.data.exam_date,
        ["firstName"]: res.data.first_name,
        ["lastName"]: res.data.last_name,
        ["email"]: res.data.email,
        ["birthday"]: res.data.birthday,
        ["passedLicense"]: res.data.passed_license,
        ["submittedAFBA"]: res.data.submitted_afba,
        ["submittedICA"]: res.data.submitted_ica,
        ["address"]: res.data.address,
        ["license"]: res.data.license,
        ["approved_to_pay"]: res.data.license,
        ["phoneNumber"]: res.data.phone_number,
        ["npn"]: res.data.npn,
        ["agency_npn"]: res.data.agency_npn,
      });
      if (res.data.exam_date) {
        formmattedExamDate = moment(res.data.exam_date).format("yyyy-MM-DD");
        formmattedExamDate = new Date(formmattedExamDate).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formmattedExamDate = null;
      }
      setSelectedDate(formmattedExamDate);
      if (res.data.birthday) {
        formattedBirthday = moment(res.data.birthday).format("yyyy-MM-DD");
        formattedBirthday = new Date(formattedBirthday).toLocaleString(
          "en-US",
          { timeZone: "UTC" }
        );
      } else {
        formattedBirthday = null;
      }
      setSelectedBirthDate(formattedBirthday);
    });
  }, [updateFormData]);

  // handle date change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    updateFormData((formData) => ({
      ...formData,
      exam_date: date,
    }));
  };
  const handleBirthDateChange = (date) => {
    setSelectedBirthDate(date);
    updateFormData((formData) => ({
      ...formData,
      birthday: date,
    }));
  };

  const deleteDocument = (e, id) => {
    e.preventDefault();
    setErrorHandle({
      open: true,
      message: "Deleting document...",
      severity: "info",
    });
    axiosInstance.delete("documents/" + id + "/").then((res) => {
      console.log(res);
      axiosInstance.get("documents/all/" + user_id).then((result) => {
        result.data = result.data.filter(function(item) { 
          return item.category !== 'Other (In-House Only)'; 
        });
        setUserUploads(result.data);
        setErrorHandle({
          open: true,
          message: "Document deleted",
          severity: "success",
        });
      });
    });
  };
  // process form
  const handleSubmit = (e) => {
    e.preventDefault();
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const URL = baseURL + user_id + "/";
    let postData = new FormData();
    postData.append("first_name", formData.firstName);
    postData.append("last_name", formData.lastName);
    postData.append("business_entity", formData.business_entity);
    postData.append("npn", formData.npn);
    postData.append("agency_npn", formData.agency_npn);
    postData.append("email", formData.email);
    if (formData.address) {
      postData.append("address", formData.address);
    }
    if (formData.voxer) {
      postData.append("voxer", formData.voxer);
    }
    if (formData.ein) {
      postData.append("ein", formData.ein);
    }
    postData.append("id", user_id);
    postData.append("passed_license", formData.passedLicense);
    postData.append("submitted_afba", formData.submittedAFBA);
    postData.append("submitted_ica", formData.submittedICA);
    if (formData.phoneNumber) {
      postData.append("phone_number", formData.phoneNumber);
    }
    if (postimage) {
      postData.append("license", postimage.image[0]);
    }
    if (formData.exam_date) {
      formmattedExamDate = moment(formData.exam_date).format("yyyy-MM-DD");
      postData.append("exam_date", formmattedExamDate);
    }
    if (formData.birthday) {
      formattedBirthday = moment(formData.birthday).format("yyyy-MM-DD");
      console.log(formattedBirthday);
      postData.append("birthday", formattedBirthday);
    }
    axios
      .put(URL, postData, config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          setErrorHandle({
            open: true,
            message: "Profile updated",
            severity: "success",
          });
          if (res.data.license) {
            updateFormData((formData) => ({
              ...formData,
              license: "http://127.0.0.1:8000" + res.data.license,
            }));
            setPostImage(null);
          }
          if (res.data.status === 3) {
            history.push("/payment");
          }
        }
      })
      .catch(function (error) {
        var err_msg = "There was an error";
        console.log(error.response);
        try {
          if (error.response.data.non_field_errors[0]) {
            err_msg = error.response.data.non_field_errors[0];
          }
        } catch {}
        console.log(error.response);
        setErrorHandle({
          open: true,
          message: err_msg,
          severity: "error",
        });
      });
  };

  const handleChange = (e) => {
    if ([e.target.name] == "image") {
      setPostImage({
        image: e.target.files,
      });
    } else {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCheckboxChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Typography component="h1" variant="h5" align="center" mt={4}>
          Onboarding Information
        </Typography>
        <Stepper activeStep={1} sx={{ mt: 4 }}>
          <Step key={1}>
            <StepLabel>Verification</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Onboarding Information</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>Appointment With Carrier</StepLabel>
          </Step>
        </Stepper>

        <div>
          <Typography component="h1" variant="body2" align="center" my={4}>
            Congratulations!
          </Typography>
          <Typography component="h1" variant="body2" align="left" my={4}>
            You have been selected to serve First Responders. We are so excited
            to have you join the First Responder Task Force. You'll need to
            complete the onboarding process in order to be appointed with our
            carrier and begin your training. The sooner you complete these
            onboarding steps, the sooner we can get you PROTECTING LIVES and MAKING MONEY!
          </Typography>
          <Typography component="h1" variant="body2" align="left" my={4}>
            If you have questions or concerns during your
            onboarding, please email Shayla Price at
            shayla@consolidatedassurance.com. Once you read through the onboarding and licensing process in this portal, you can schedule time to get any questions answered by clicking <a href="https://calendly.com/shayla-consolidatedassurance/30min">here</a>.
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Personal" {...a11yProps(0)} />
                  <Tab label="Business" {...a11yProps(1)} />
                  <Tab label="Licensing" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      id="address"
                      name="address"
                      label="Address"
                      fullWidth
                      onChange={handleChange}
                      value={formData.address}
                      inputRef={materialRef}
                      helperText="Your primary mailing address."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="phone_number"
                      name="phoneNumber"
                      label="Phone Number"
                      fullWidth
                      onChange={handleChange}
                      value={formData.phoneNumber || ""}
                      variant="outlined"
                      helperText="The best phone number to reach you at."
                    />
                  </Grid>{" "}
                  <Grid item xs={12}>
                  <TextField
                    id="voxer"
                    name="voxer"
                    label="Voxer Handle"
                    fullWidth
                    onChange={handleChange}
                    value={formData.voxer || ""}
                    variant="outlined"
                    helperText="Your Voxer handle"
                  />
                </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-inline"
                      label="Date of Birth"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      dateFormat="yyyy-MM-dd"
                      fullWidth
                      value={selectedBirthDate}
                      onChange={handleBirthDateChange}
                      inputVariant="outlined"
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      We contract with businesses. If you have an existing
                      business with an assigned EIN, you are welcome to use that
                      business for this purpose. If you do not already have a
                      business with an assigned EIN, set one up. You cannot use
                      a name with the words First Responder Task Force in them,
                      as that is trademarked. Your
                      business name will be your agency name and will appear on
                      internal communications. An EIN# can be obtained in a
                      matter of minutes via the{" "}
                      <a href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online">
                        IRS’ website
                      </a>
                      . If you need guidance setting up your business, please
                      contact an accountant/legal resource to assist. We recommend <a href="https://www.rocketlawyer.com">
                        Rocket Lawyer
                      </a> ($39/mo).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="business_entity"
                      name="business_entity"
                      label="Business Entity"
                      fullWidth
                      onChange={handleChange}
                      value={formData.business_entity || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="ein"
                      name="ein"
                      label="Business EIN"
                      fullWidth
                      onChange={handleChange}
                      value={formData.ein || ""}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      Life Insurance Exam
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      The online study guide program we use and recommend is{" "}
                      <a href="https://www.examfx.com">www.examfx.com</a>. We recommend
                      that this step be completed (and your exam scheduled!) in
                      1 week.
                    </Typography>

                    <Typography variant="body2" sx={{ my: 1 }}>
                      When you go to <a href="https://www.examfx.com">www.examfx.com</a>
                      , go to Register in the top right corner. Under Product
                      Details use the email: joseph@consolidatedassurance.com to
                      get discounted pricing. There are 2 options you can
                      purchase at our discounted rate of 40% off (prices reflect
                      discounted rate). Choose whichever option best suits your
                      studying needs. You need to select the Life Exam Option.
                      Schedule your exam in advance.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="date-picker-inline"
                      label="License Exam Date"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      dateFormat="yyyy-MM-dd"
                      fullWidth
                      value={selectedDate}
                      onChange={handleDateChange}
                      inputVariant="outlined"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          helperText="Once you have scheduled your license exam, enter it above"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      State Insurance Licenses
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Each state has differing requirements for licensing. We
                      strongly suggest you check{" "}
                      <a href="https://www.nipr.com/licensing-center/state-requirements">
                        NIPR State Requirements
                      </a>{" "}
                      to see the specific requirements for your state(s) to
                      avoid delays.
                    </Typography>

                    <Typography variant="h6" sx={{ my: 1 }}>
                      Individual Insurance License
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Apply for your state license, when you have passed your
                      test. This is usually done at the testing center
                      immediately following the exam. If that option is not made
                      available to you, then go to{" "}
                      <a href="https://www.nipr.com">www.nipr.com</a>. Click "Apply for
                      License" and select “Individual." Follow prompts. Email
                      shayla@consolidatedassurance.com and let her know the good
                      news when you pass.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      <b>Note</b>: As you are entering your licensing
                      information to apply, our coordinators typically enter in
                      our info: 888-333-3403/licensing@frtf.us so you never miss
                      any continuing education credit info.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      When you receive your Individual license, it will include
                      your individual National Producer Number.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="npn"
                      name="npn"
                      label="National Producer Number (Individual)"
                      fullWidth
                      onChange={handleChange}
                      value={formData.npn || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Once you have your individual license and return your Independant Contractor Agreement, we will begin your pre-launch training.
                    </Typography>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      Business Insurance License
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      After receiving your Individual license, apply for your
                      Business License. You must have your individual license
                      AND your business with an EIN set up before you'll be able
                      to apply. That means that not only will you as an
                      individual be a licensed agent with your state, but your
                      business (aka agency) will also be licensed through your
                      EIN.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Go to <a href="https://www.nipr.com">www.nipr.com</a>. Click
                      "Apply for license" and select "Agency" (instead of
                      individual, which you did previously).
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      If you are going to be working outside of your resident
                      state, you must repeat these steps as a non-resident for each state you will
                      be selling in.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      When you receive your Business license, it will include
                      your business National Producer Number.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="agency_npn"
                      name="agency_npn"
                      label="National Producer Number (Business)"
                      fullWidth
                      onChange={handleChange}
                      value={formData.agency_npn || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      When you receive your business insurance license, upload
                      it to the appropriate category at the bottom of this page.
                    </Typography>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      Errors &amp; Omissions Insurance
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Errors and omissions insurance (E&amp;O) is a type of
                      professional liability insurance that protects companies,
                      their workers, and other professionals against claims of
                      inadequate work or negligent actions.
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Obtain your Errors and Omissions Certificate (E&O). You
                      can use whoever you wish, but we personally use{" "}
                      <a href="https://www.napa-benefits.org/errors-and-omissions">
                        NAPA
                      </a>
                      . You'll need the Option A, for a newly licensed Life
                      Agent.{" "}
                    </Typography>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      AFBA Contract
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>The last step of onboarding is contracting with our carrier, AFBA. The AFBA contracting packet is sent in an email once you have completed licensing and obtained your E&O coverage. Upon completion, upload your AFBA contracting packet here.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ my: 1 }}>
                      Document Uploads
                    </Typography>
                    <Typography variant="body2" sx={{ my: 1 }}>
                      Once you obtain your Individual Insurance License,
                      Business Insurance License, and Errors &amp; Omissions
                      Insurance please upload the documents below.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  spacing={2}
                  my={2}
                  alignContent="flex-start"
                >
                  <Grid container item xs={4} alignContent="flex-start">
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={categoryChange}
                          label="Category"
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value={2}>Individual License</MenuItem>
                          <MenuItem value={3}>Agency License</MenuItem>
                          <MenuItem value={4}>E&O</MenuItem>
                          <MenuItem value={5}>AFBA Contract</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid item xs={12} align="left" mt={1}>
                        <MobileDatePicker
                          label="Expiration Date"
                          inputFormat="MM/dd/yyyy"
                          value={selectedExpirationDate}
                          onChange={handleExpirationDateChange}
                          name="exam_date"
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </Grid>
                    </LocalizationProvider>
                    <Grid item xs={12} mt={1}>
                      <span>{buttonName}</span>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <input
                        id="file-upload"
                        onChange={handleFileChange}
                        name="file-upload"
                        type="file"
                        style={{ display: "none" }}
                      />
                      <label htmlFor="file-upload">
                        <Button
                          variant="contained"
                          color="secondary"
                          component="span"
                          startIcon={<CloudDownloadIcon />}
                          fullWidth
                          size="large"
                        >
                          Select File
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        fullWidth
                        onClick={handleFileSubmit}
                        startIcon={<CloudUploadIcon />}
                        size="large"
                      >
                        Upload File
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container item xs={8}>
                    <Grid item xs={12} align="left" direction="row">
                      {userUploads.map((item) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.category} -{" "}
                          {moment(item.date_added)
                            .format("MM/DD/yyyy h:mma")
                            .toLocaleString("en-US", { timeZone: "EST" })}{" "}
                          <Link href={item.file} target="_blank">
                            <PreviewIcon
                              align="flexCenter"
                              sx={{ display: "block" }}
                            />
                          </Link>
                          {"Expires - " + (item.expiration_date || "N/A")}
                          <Link
                            onClick={(e) => deleteDocument(e, item.id)}
                            href=""
                          >
                            <DeleteForeverIcon
                              color="error"
                              align="flexCenter"
                              sx={{ display: "block" }}
                            />
                          </Link>
                        </div>
                      ))}
                      <Typography variant="body2" sx={{ my: 0 }}>
                        Your uploads will appear here.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
            <Grid container spacing={2}></Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ my: 4 }}
            >
              Update
            </Button>
          </form>
        </div>
        <Snackbar
          open={errorHandle.open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={errorHandle.severity}
            sx={{ width: "100%" }}
          >
            {errorHandle.message}
          </Alert>
        </Snackbar>
      </Container>
    </LocalizationProvider>
  );
}
