import "./App.css";
import Header from "./components/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import { CssBaseline } from "@mui/material";
import Login from "./components/Login";
import Logout from "./components/Logout";
import ViewUser from "./components/ViewUser";
import Unauthorized from "./components/Unauthorized";
import Territories from "./components/Territories";
import Hierarchy from "./components/Hierarchy";
import Onboard from "./components/Onboard";
import UserOnboarding from "./components/UserOnboarding";
import Payment from "./components/Payment";
import Recruiting from "./components/Recruiting";
import Counties from "./components/Counties";
import Retention from "./components/Retention";
import RetentionManagement from "./components/RetentionManagement";
import FRBA from "./components/FRBA";
import PaymentCompleted from "./components/PaymentCompleted";
import RetentionEntry from "./components/RetentionEntry";
import Appointed from "./components/Appointed";
import OnboardingAdmin from "./components/OnboardingAdmin";
import TrainingAdmin from "./components/TrainingAdmin";
import AddUser from "./components/AddUser";
import RetentionDashboard from "./components/RetentionDashboard";

function App() {
  return (
    <Router>
      <CssBaseline />
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/view/:id" component={ViewUser} />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/territories/:id?" component={Territories} />
        <Route exact path="/hierarchy" component={Hierarchy} />
        <Route exact path="/onboard/:regcode" component={Onboard} />
        <Route exact path="/onboarding" component={UserOnboarding} />
        <Route exact path="/payment" component={Payment} />
        <Route exact path="/completed" component={PaymentCompleted} />
        <Route exact path="/recruiting" component={Recruiting} />
        <Route exact path="/counties" component={Counties} />
        <Route exact path="/retention" component={Retention} />
        <Route exact path="/retention/manage" component={RetentionManagement} />
        <Route exact path="/retention/entry" component={RetentionEntry} />
        <Route exact path ="/frba" component={FRBA} />
        <Route exact path ="/appointment" component={Appointed} />
        <Route exact path ="/onboardingadmin" component={OnboardingAdmin} />
        <Route exact path ="/trainingadmin" component={TrainingAdmin} />
        <Route exact path ="/adduser" component={AddUser} />
        <Route exact path ="/retentiondashboard" component={RetentionDashboard} />
      </Switch>
    </Router>
  );
}

export default App;
