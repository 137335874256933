import {
  CircularProgress,
  Collapse,
  Container,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { Chart } from "chart.js";
import React, { Component, useEffect, useRef, useState } from "react";
import axiosInstance from "../axiosApi";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Row(props) {
  const { item } = props;
  const [open, setOpen] = useState(false);
  const handleFullEditClick = (id) => (event) => {
    event.stopPropagation();
    const win = window.open("/view/" + id + "/", "_blank");
    win.focus();
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
        </TableCell>
        <TableCell><a href="" onClick={handleFullEditClick(item.id)}>{item.first_name + " " + item.last_name}</a></TableCell>
        <TableCell align="center">
          {moment(item.estimated_launch).format("MM/DD/YY")}
        </TableCell>
        <TableCell align="center">
          {moment(item.launch_date).format("MM/DD/YY")}
        </TableCell>
        <TableCell align="center">
          {moment(item.launch_date).add(3, "months").format("MM/DD/YY")}
        </TableCell>
        <TableCell align="center">
          {moment(item.launch_date).add(6, "months").format("MM/DD/YY")}
        </TableCell>
        <TableCell align="center">
          {item.training_kick_off ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.ipc ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.ib && item.business_entity ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.ipc_ib_certified ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.gb ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.one_on_one ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.gb_one_on_one_certified ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.ready_for_launch ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
        <TableCell align="center">
          {item.launched ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CancelIcon color="error" />
          )}
        </TableCell>
      </TableRow>
 
    </React.Fragment>
  );
}

export default function TrainingAdmin() {
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    axiosInstance.get("traininglist").then((res) => {
      setUserList(res.data);
    });
  }, [setUserList]);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth="xl">
      <Typography variant="h6" textAlign="center" mb={4}>
        Training
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Candidate</TableCell>
              <TableCell align="center">Est. Launch Date</TableCell>
              <TableCell align="center">Launch Date</TableCell>
              <TableCell align="center">Three Month Date</TableCell>
              <TableCell align="center">Six Month Date</TableCell>
              <TableCell align="center">Kick Off</TableCell>
              <TableCell align="center">IPC</TableCell>
              <TableCell align="center">IB</TableCell>
              <TableCell align="center">IPC/IB Certified</TableCell>
              <TableCell align="center">GB</TableCell>
              <TableCell align="center">One on One</TableCell>
              <TableCell align="center">GB/One on One Certified</TableCell>
              <TableCell align="center">Ready for Launch</TableCell>
              <TableCell align="center">Launched</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList
              .map(function (item) {
              return <Row key={item.id} item={item} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
