import { Container, CssBaseline, Typography } from "@mui/material";
import { useEffect } from "react";
import axiosInstance from "../axiosApi";
import React from "react";
import { useState } from "react";
import DataTree from "./renderTree";

function Hierarchy() {

  const [treeData, setTreeData] = useState({});

  useEffect(() => {
    axiosInstance
      .get('/hierarchy')
      .then((res) =>  {
        setTreeData(
            {
                'id': 1,
                'user': 'Consolidated Assurance',
                'children': res.data
            })
      })
    }, setTreeData);

  return (
    <Container maxWidth="md">
        <CssBaseline />
        <div className="NotYetDeveloped">
        <Typography variant="h4" mb={0} pb={0}>Hierarchy</Typography>
          <div align="left">
            <DataTree data={treeData} />
          </div>
        </div>
    </Container>
  );
}

export default Hierarchy;

