import React, { Component, useEffect, useRef, useState } from "react";
import axiosInstance from "../../../axiosApi";
import { Chart } from "chart.js";
import moment from "moment";

export default function WeeklyRetentionTotalsChart(props) {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({});
  const [chartType, setChartType] = useState(props.type);
  const [chartOptions, setChartOptions] = useState({
    response: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Weekly Retention Resolves",
      },
    },
  });

  let backgroundColors = {
    DominiqueOverstreet: {
      backgroundColor: "rgb(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235)",
    },
    JulienneTrees: {
      backgroundColor: "rgb(255, 99, 132, 0.6)",
      borderColor: "rgba(255, 99, 132)",
    },
    KaylaBurget: {
      backgroundColor: "rgb(255, 150, 0, 0.6)",
      borderColor: "rgb(255, 150, 0)",
    },
    NatashaSmithJones: {
      backgroundColor: "rgb(100, 100, 250, 0.6)",
      borderColor: "rgb(100, 100, 250)",
    },
    LatoshaGordon: {
      backgroundColor: "rgb(0, 150, 0, 0.6)",
      borderColor: "rgb(0, 150, 0)",
    },
    LatoyaWilliams: {
      backgroundColor: "rgb(150, 0, 0, 0.6)",
      borderColor: "rgb(150, 0, 0)",
    },
  };

  useEffect(() => {
    axiosInstance
      .get("agentresolves/allweekly/")
      .then(function (response) {
        myChartRef = chartRef.current.getContext("2d");

        if (typeof myChart !== "undefined") myChart.destroy();

        data = {
          labels: response.data.map((item) =>
            moment()
              .day("Monday")
              .week(item.week)
              .year(item.year)
              .format("MM/DD/YY")
          ),
          datasets: [
            {
              label: "Weekly Resolves",
              data: response.data.map((item) => item.total),
              backgroundColor: "rgb(54, 162, 235, 0.6)",
      borderColor: "rgba(54, 162, 235)",
              lineTension: 0.1,
            },
          ],
        };

        setChartData(data);

        myChart = new Chart(myChartRef, {
          type: "line",
          data: data,
          options: chartOptions,
        });
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [setChartData]);

  return (
    <div>
      <canvas id="WeeklyRetentionTotalsChart" ref={chartRef} />
    </div>
  );
}
