import {
  Alert,
  Avatar,
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import axiosInstance from "../axiosApi";
import {
  useGridApiRef,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import moment from "moment";
import DailyResolveTotalsByAgentChart from "./charts/resolve_charts/DailyRetentionTotalsByAgentChart";
import { NavLink } from "react-router-dom";

function renderAgent(params) {
  const agents = {
    1: "Emileigh",
    2: "Jules",
    3: "Conner",
    4: "Kayla",
  };
  return agents[params.value];
}

export default function RetentionEntry() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalResolve, setTotalResolve] = useState(0);
  const [totalResolveMP, setTotalResolveMP] = useState(0);
  const [dailyResolves, setDailyResolves] = useState([]);
  const [forceReload, setForceReload] = useState(1);
  const [formData, setFormData] = useState({
    made_current: true,
    months_resolved: 1,
  });
  const [errorHandle, setErrorHandle] = useState({
    open: false,
    message: null,
    severity: null,
  });

  let user_id = localStorage.getItem("user_id");

  const forceReloadClick = () => {
    setForceReload(forceReload + 1);
  };

  const handleDelete = (chipToDelete) => () => {
    setDailyResolves((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );

    setTotalResolve(totalResolve - Number(chipToDelete.policies_resolved));
    setTotalResolveMP(totalResolveMP - Number(chipToDelete.monthly_premium));

    axiosInstance
      .delete(`agentresolves/individual/` + chipToDelete.key + `/`, {})
      .then((result) => {
        console.log(result);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorHandle({ ...errorHandle, open: false });
  };

  const handleChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckboxChange = (e) => {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleSubmit = (e) => {
    setTotalResolve(totalResolve + Number(formData.policies_resolved));
    setTotalResolveMP(totalResolveMP + Number(formData.monthly_premium));
    if (
      (formData.policies_resolved && formData.monthly_premium) !== undefined
    ) {
      console.log(formData);
    }
    var chip_made_current = "default";
    if (formData.made_current) {
      chip_made_current = "primary";
    }
    setDailyResolves([
      ...[
        {
          monthly_premium: formData.monthly_premium,
          made_current: chip_made_current,
          policies_resolved: formData.policies_resolved,
          key: Math.floor(Date.now() / 1000),
        },
      ],
      ...dailyResolves,
    ]);

    axiosInstance
      .post(`agentresolves/individual/`, {
        monthly_premium: Number(formData.monthly_premium),
        policies: Number(formData.policies_resolved),
        agent: user_id,
        made_current: formData.made_current,
      })
      .then((result) => {
        console.log(result);
      });

    setFormData({
      made_current: true,
      months_resolved: 1,
      policies_resolved: "",
      monthly_premium: "",
    });

    setErrorHandle({
      open: true,
      message: "Resolve Added",
      severity: "success",
    });
  };

  useEffect(() => {
    axiosInstance
      .get(`agentresolves/individual/` + user_id + `/`)
      .then((result) => {
        console.log(result);
        let result_array = [];
        let resolve_total = 0;
        let resolve_mp = 0;
        result.data.map((item) => {
          resolve_total += Number(item.policies);
          resolve_mp += Number(item.monthly_premium);
          var chip_made_current = "default";
          if (item.made_current) {
            chip_made_current = "primary";
          }
          result_array.push({
            monthly_premium: item.monthly_premium,
            made_current: chip_made_current,
            policies_resolved: item.policies,
            key: item.id,
          });
        });
        setDailyResolves(result_array);
        setTotalResolve(resolve_total);
        setTotalResolveMP(resolve_mp);
      });
  }, []);

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container sx={{ my: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            component={NavLink}
            variant="contained"
            color="primary"
            to="/retention/"
          >
            View All Resolves
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="success"
            variant="body"
            component="body"
            textAlign="center"
            mb={1}
          >
            Total Resolves: {totalResolve}
          </Typography>
          <Typography
            color="success"
            variant="body"
            component="body"
            textAlign="center"
            mb={4}
          >
            Total Monthly Premium: ${totalResolveMP}
          </Typography>
        </Grid>
        <Grid container xs={12} spacing={1}>
          {/* <Grid item xs={12}>
            <DailyResolveTotalsByAgentChart/>
          </Grid> */}
          <Grid item xs={12}>
            <Typography mb={2}>
              Add your payments and premiums collected below.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="policies_resolved"
              name="policies_resolved"
              label="Payments Made"
              type="number"
              value={formData.policies_resolved}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="monthly_premium"
              name="monthly_premium"
              label="Premium Collected"
              type="number"
              value={formData.monthly_premium}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6} align="right">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    name="made_current"
                    id="made_current"
                    color="primary"
                    labelPlacement="start"
                    checked={formData.made_current}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Account Made Current"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
            >
              Add Resolve
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          spacing={1}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Typography my={2}>Today's Resolves</Typography>
          </Grid>
          <Grid item xs={12}>
            {dailyResolves.map((item) => (
              <Chip
                sx={{ mr: 1, mb: 1 }}
                label={"$" + item.monthly_premium}
                color={item.made_current}
                onDelete={handleDelete(item)}
                avatar={<Avatar>{item.policies_resolved}</Avatar>}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={errorHandle.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={errorHandle.severity}
          sx={{ width: "100%" }}
        >
          {errorHandle.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
