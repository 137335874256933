import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useHistory, useParams } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, ElementsConsumer, useElements, useStripe} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import axiosInstance from '../axiosApi';
import { Typography } from '@mui/material';


export default function Payment() {


  var url = new URL(window.location.href)
  var payment_result = url.searchParams.get('success');
  var session_id = url.searchParams.get('id');

  let user = localStorage.getItem('username');
  let user_id = localStorage.getItem('user_id');
	const history = useHistory();

  const initialUserData = Object.freeze({
    'stripe_customer_id': '',
  });

  const initialFormData = Object.freeze({
   approvedToPay: false,
   stripeCustomerId: null,
  });

  const [userData, updateUserData] = useState(initialUserData);
  const [formData, updateFormData] = useState(initialFormData);
  

  // get user info
  useEffect(() => {
    axiosInstance
      .get(user_id)
      .then((res) =>  {
        updateFormData({
          ...formData,
          ['approvedToPay']: res.data.approved_to_pay,
          ['stripeCustomerID']: res.data.stripe_customer_id,
        });
      });
  }, [updateFormData]);

  if(formData.stripeCustomerID) {
    history.push('/completed');
  }

  useEffect(() => {
    if(session_id) {
      axiosInstance
        .post('/create_success', {
          'user': user_id,
          'session_id': session_id,
        })
        .then((res) => {
          updateUserData({
            ...userData,
            ['customer_id']: res.data.customer_id,
          });
          history.push('/completed');
        });
      }
  }, [updateUserData]);


  
  const [errorHandle, setErrorHandle ] = useState({
    open: false,
    message: null,
    severity: null,
});
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorHandle({open: false});
  };

  
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
        <Stepper activeStep={3} sx={{ mt: 4 }}>
          <Step key={1}>
            <StepLabel>Verification</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Onboarding Information</StepLabel>
          </Step>
          <Step key={3}>
            <StepLabel>Appointment With Carrier</StepLabel>
          </Step>
        </Stepper>
        <div>
        <Typography align="center" component="h1" variant="h5" my={2}>
        Appointment
        </Typography>
       
          <p align="center">All of your onboarding information has been received. We are working on getting you appointed with our carrier. We will reach out to you when this has been completed.</p>
      </div>
    </Container>
    
  );
}