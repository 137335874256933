import React, { Component, useEffect, useRef, useState } from "react";
import { Chart } from "chart.js";
import axios from "axios";
import _ from "lodash";

export default function AgentActivityChart({ apiEndpoint, title }) {
  const chartRef = useRef();
  let myChart;
  let data;
  let myChartRef;
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: title,
      },
    },
    cutout: "50%",
  });
  const [state, setState] = React.useState();
  const forceUpdate = () => {
    if (typeof myChart !== "undefined") myChart.destroy();
  };

  const calltoolsAxiosInstance = axios.create({
    baseURL: "https://east-1.calltools.io/api/",
    timeout: 30000,
    headers: {
      Authorization: "Token 5d5f0948893af9149f08ec03613b5040d72b8f5d",
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    }
  }

  async function ExecuteRequest(url, data) {
    // As this is a recursive function, we need to be able to pass it the prevous data. Here we either used the passed in data, or we create a new objet to hold our data.
    data = data || {};
    await calltoolsAxiosInstance.get(url).then((response) => {
      // We merge the returned data with the existing data
      _.mergeWith(data, response.data, customizer);
      // We check if there is more paginated data to be obtained
      if (response.data.next != null) {
        // If nextPageUrl is not null, we have more data to grab
        return ExecuteRequest(response.data.next, data);
      }
    });
    return data;
  }
  useEffect(() => {
    ExecuteRequest(apiEndpoint).then((data) => {
      var dataset_array = [];
      var inbound = [];
      var outbound = [];
      var total = [];
      var labels = [];

      data.records.map(function (obj) {
        dataset_array.push({
          label: obj["Full Name"],
          data: [obj["Calls"]],
        });
        if (obj["Calls"] > 0) {
          outbound.push(obj["Calls Outbound"]);
          inbound.push(obj["Calls Inbound"]);
          total.push(obj["Calls"]);
          labels.push(obj["Full Name"]);
        }
      });

      var arrayOfObj = labels.map(function (d, i) {
        return {
          label: d,
          inbound: inbound[i] || 0,
          outbound: outbound[i] || 0,
          total: total[i] || 0,
        };
      });

      var sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
        return b.total - a.total;
      });

      var newArrayLabel = [];
      var newArrayInbound = [];
      var newArrayOutbound = [];
      var newArrayTotal = [];

      sortedArrayOfObj.forEach(function (d) {
        newArrayLabel.push(d.label);
        newArrayInbound.push(d.inbound);
        newArrayOutbound.push(d.outbound);
        newArrayTotal.push(d.total);
      });

      myChartRef = chartRef.current.getContext("2d");
      data = {
        labels: newArrayLabel,
        datasets: [
          {
            label: "Total Calls",
            data: newArrayTotal,
            backgroundColor: ["rgba(63,87,255, .7)"],
            borderColor: ["rgba(63,87,255, 1)"],
            borderWidth: 3,
            borderRadius: 3,
          },
          {
            label: "Inbound Calls",
            data: newArrayInbound,
            backgroundColor: ["rgba(63,122,255, .7)"],
            borderColor: ["rgba(63,122,255, 1)"],
            borderWidth: 3,
            borderRadius: 3,
          },
          {
            label: "Outbound Calls",
            data: newArrayOutbound,
            backgroundColor: ["rgba(63, 186, 255, .7)"],
            borderColor: ["rgba(63, 186, 255, 1)"],
            borderWidth: 3,
            borderRadius: 3,
          },
        ],
      };
      myChart = new Chart(myChartRef, {
        type: "bar",
        data: data,
        options: chartOptions,
      });
    });
  }, [state, setChartLabels, setChartData]);
  return (
    <div>
      <canvas id="AgentActivityChart" ref={chartRef} />
    </div>
  );
}
