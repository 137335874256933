import React, { useEffect } from 'react';
import axiosInstance from '../axiosApi';
import { useHistory } from 'react-router-dom';

export default function SignUp() {
	const history = useHistory();

	useEffect(() => {
		const response = axiosInstance.post('user/logout/blacklist/', {
			refresh_token: localStorage.getItem('refresh_token'),
		});
		localStorage.removeItem('access_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('user_id');
		localStorage.removeItem('username');
        localStorage.removeItem('user_status');
		axiosInstance.defaults.headers['Authorization'] = null;
		window.location.href = '/';
	});
	return <div></div>;
}
