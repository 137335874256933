import { useEffect, useRef } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";

export default function SmallMapComponent(props) {
  const SmallMapComponentReturn = () => {
    const ref = useRef();

    var new_counties = [];
    var population_scale = [];
    [props.data].map(function (group) {
      group.map(function (item) {
        new_counties.push(item.fips_code);
        population_scale.push(item.population);
      });
    });

    population_scale.sort((a, b) => a - b);

    var scale_length = population_scale.length + 1;

    useEffect(() => {
      var width = 900,
        height = 600,
        centered;
      const svgElement = d3
        .select(ref.current)
        .append("svg")
        .attr("preserveAspectRatio", "none")
        .attr("viewBox", [0, 0, width, height]);

      const div_small = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip small_tooltip")
        .style("opacity", 0);

      var path = d3.geoPath();
      var colorScale = d3
        .scaleLinear()
        .domain([
          population_scale[0],
          population_scale[population_scale.length - 1],
        ])
        .range(["#c8e4fb", "#093170"]);

      d3.json("https://d3js.org/us-10m.v2.json").then((d) => {
        d.objects.counties.geometries = d.objects.counties.geometries.filter(
          function (item) {
            return new_counties.indexOf(item.id) > -1;
          }
        );

        var geojson = topojson.feature(d, d.objects.counties);
        var projection = d3.geoIdentity().fitSize([width, height], geojson);
        path.projection(projection);
        var counties = geojson.features;
        var div = d3
          .select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);

        svgElement
          .append("g")
          .attr("class", "zoomed-county")
          .selectAll("path")
          .data(counties)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("class", "zoomed-county-hover")
          .attr("fill", function (d) {
            var to_return;
            [props.data].map(function (group) {
              group.map(function (item) {
                if (item.fips_code == d.id) {
                  to_return = colorScale(item.population);
                }
              });
            });
            return to_return;
          })
          .attr("hover_data", function (d) {
            return d.id;
          })
          .on("mouseover", function (event, t) {
            var to_return;
            [props.data].map(function (group) {
              group.map(function (item) {
                if (item.fips_code == t.id) {
                  to_return = item.population;
                }
              });
            });
            div_small.transition().duration(200).style("opacity", 0.9);
            div_small
              .html(t.properties.name + '<br>' + 'Population: ' + to_return)
              .style("left", d3.pointer(event, "html")[0] - 30 + "px")
              .style("top", d3.pointer(event, "html")[1] - 60 + "px");
          })
          .on("mouseout", function (d) {
            div_small.transition().duration(500).style("opacity", 0);
          });
      });
    }, []);

    return <div ref={ref} className="mapwrap special"></div>;
  };

  return <SmallMapComponentReturn />;
}
