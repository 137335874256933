import {
  Avatar,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";
import axiosInstance from "../axiosApi";
import React from "react";
import { Dialog } from "@mui/material";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Slide } from "@mui/material";
import { Grid } from "@mui/material";
import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import SmallMapComponent from "./SmallMapComponent";
import { Chip } from "@mui/material";
import { Stack } from "@mui/material";
import { useHistory, useParams } from "react-router";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { LinearProgress } from "@mui/material";
import { NavLink } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditToolbar(props) {
  const { apiRef } = props;
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

function Territories() {
  const apiRef = useGridApiRef();
  const [open, setOpen] = React.useState(false);
  const [territoryData, setTerritoryData] = React.useState([]);
  const [smallMapData, setSmallMapData] = React.useState([]);
  const [territoryList, setTerritoryList] = React.useState([]);
  const [openTerritories, setOpenTerritories] = React.useState(0);
  const [managedTerritories, setManagedTerritories] = React.useState(0);
  const [onboardingTerritories, setOnboardingTerritories] = React.useState(0);
  const [trainingdTerritories, setTrainingTerritories] = React.useState(0);
  const [coordinatorTerritories, setCoordinatorTerritories] = React.useState(0);
  const history = useHistory();
  const territory_to_show = useParams().id;
  const [isLoading, setIsLoading] = useState(true);

  const handleClickOpen = (data) => {
    axiosInstance
      .get("/territory/" + data.territory_id + "/")
      .then((result) => {
        setTerritoryData((territoryData) => ({
          ...territoryData,
          ["territory_name"]: result.data.territory_name,
          ["territory_counties"]: result.data.counties,
          ["territory_cities"]: result.data.cities.sort((a,b) => b.population - a.population),
          ["territory_population"]: result.data.population,
          ["assigned_user_name"]: null,
          ["assigned_user_email"]: null,
          ["assigned_user_id"]: null,
          ["previous_users"]: result.data.previous_users,
        }));

        if (result.data.assigned_user[0]) {
          setTerritoryData((territoryData) => ({
            ...territoryData,
            ["assigned_user_name"]: result.data.assigned_user[0].name,
            ["assigned_user_email"]: result.data.assigned_user[0].email,
            ["assigned_user_id"]: result.data.assigned_user[0].id,
          }));
        }
        if (data.small_map_data) {
          setSmallMapData(data.small_map_data);
        }
      });
    setOpen(true);
    history.push("/territories/" + data.territory_id);
  };

  const handleClose = () => {
    setOpen(false);
    history.push("/territories/");
  };

  const HighLightItem = (e) => {
    try {
      var highlight_id = e.target.id;
      const select = document.querySelector(
        "[hover_data='" + highlight_id + "']"
      );
      select.classList.add("zoomed-county-hover-sim");
    } catch {
      return;
    }
  };

  const UnHighlightItem = (e) => {
    try {
      var highlight_id = e.target.id;
      const select = document.querySelector(
        "[hover_data='" + highlight_id + "']"
      );
      select.classList.remove("zoomed-county-hover-sim");
    } catch {
      return;
    }
  };

  useEffect(() => {
    axiosInstance.get("tlist").then((result) => {
      setTerritoryList(result.data);
      setIsLoading(false);
    });
  }, [setTerritoryList]);

  const MapComponent = () => {
    const ref = useRef();

    useEffect(() => {
      var width = 960,
        height = 600,
        centered;

      const zoom = d3.zoom().scaleExtent([1, 8]).on("zoom", zoomed);

      function zoomed(event) {
        const { transform } = event;
        d3.select("g").attr("transform", transform);
        d3.select("g").attr("stroke-width", 1 / transform.k);
      }

      const svgElement = d3
        .select(ref.current)
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", [0, 0, width, height]);

      const div = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      var path = d3.geoPath();

      const svgG = svgElement.append("g").attr("class", "counties");
      d3.json("https://d3js.org/us-10m.v2.json").then((d) => {
        svgG
          .selectAll("path")
          .data(topojson.feature(d, d.objects.counties).features)
          .enter()
          .append("path")
          .attr("d", path);

        var managed = [];
        var unmanaged = [];
        var onboarding = [];
        var training = [];
        var coordinator = [];

        territoryList.map((k, v) => {
          var counties = k.counties;
          var names = k.territory_name;
          var status = k.managed;
          var new_counties = [];
          [counties].map(function (group) {
            group.map(function (item) {
              new_counties.push(item.fips_code);
            });
          });
          var geo = [new_counties].map(function (group) {
            return topojson.merge(
              d,
              d.objects.counties.geometries.filter(function (a) {
                return group.indexOf(a.id) > -1;
              })
            );
          });
          k.cities.sort((a,b) => b.population - a.population)
          var cities_for_label = [];
          for (var city in k.cities) {
            cities_for_label.push(k.cities[city]['name'])
          }
          geo[0].label = '<b>'+k.territory_name + '</b> - ' + cities_for_label.slice(0,3).join(', ');
          geo[0].coordinator = "";
          geo[0].status = "";
          geo[0].launched = "";
          if (k.assigned_user[0]) {
            geo[0].coordinator = k.assigned_user[0].name;
            geo[0].status = k.assigned_user[0].status;
            geo[0].launched = k.assigned_user[0].launched;
          }
          geo[0].territory_id = k.id;
          geo[0].county_fips_codes = new_counties;
          geo[0].small_map_data = counties;
          if (status === true) {
            managed.push(geo[0]);
          } else {
            unmanaged.push(geo[0]);
          }
          if (territory_to_show == k.id) {
            setSmallMapData(geo[0].small_map_data);
          }
        });

        setOpenTerritories(unmanaged.length - 1);
        setManagedTerritories(managed.length);

        svgG
          .append("path")
          .attr("class", "countyborders")
          .attr(
            "d",
            path(
              topojson.mesh(d, d.objects.counties, function (a, b) {
                return a !== b;
              })
            )
          );

        svgG
          .selectAll(null)
          .data(managed)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("class", function (t) {
            if (t.status < 5 || t.status == 7) {
              onboarding.push(t);
              return "onboarding-territory";
            } else if (t.status == 5 && t.launched == false) {
              training.push(t);
              return "training-territory";
            } else {
              coordinator.push(t);
              return "filled-territory";
            }
          })
          .on("click", function (t) {
            div.transition().duration(500).style("opacity", 0);
            handleClickOpen(t.target.__data__);
          })
          .on("mouseover", function (event, t) {
            div.transition().duration(200).style("opacity", 0.9);
            div
              .html(t.label + "<br>" + t.coordinator)
              .style("left", d3.pointer(event, "html")[0] - 30 + "px")
              .style("top", d3.pointer(event, "html")[1] - 60 + "px");
          })
          .on("mouseout", function (d) {
            div.transition().duration(500).style("opacity", 0);
          });

        setCoordinatorTerritories(coordinator.length);
        setTrainingTerritories(training.length);
        setOnboardingTerritories(onboarding.length);

        svgG
          .selectAll(null)
          .data(unmanaged)
          .enter()
          .append("path")
          .attr("d", path)
          .attr("class", "unfilled-territory")
          .on("click", function (t) {
            div.transition().duration(500).style("opacity", 0);
            handleClickOpen(t.target.__data__);
          })
          .on("mouseover", function (event, t) {
            div.transition().duration(200).style("opacity", 0.9);
            div
              .html(t.label)
              .style("left", d3.pointer(event, "html")[0] - 30 + "px")
              .style("top", d3.pointer(event, "html")[1] - 60 + "px");
          })
          .on("mouseout", function (d) {
            div.transition().duration(500).style("opacity", 0);
          });

        svgElement.call(zoom);
      });
    }, []);

    return <div ref={ref} className="mapwrap"></div>;
  };

  useEffect(() => {
    if (territory_to_show) {
      handleClickOpen({ territory_id: territory_to_show });
    }
  }, []);
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const returnAssignedUser = (p) => {
    if (typeof p.row.assigned_user[0] === "undefined") {
      return "Not Assigned";
    } else {
      return p.row.assigned_user[0].name;
    }
  };
  function getTerritoryName(p) {
    if (p.row.territory_name) {
      return p.row.territory_name;
    } else {
      return "X - Unassigned";
    }
  }

  const small_columns = [
    { field: "county_name", headerName: "County", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "population", headerName: "Population", flex: 1 },
  ];

  const city_columns = [
    { field: "city_name", headerName: "City", flex: 1 },
    { field: "county_name", headerName: "County", flex: 1 },
    { field: "state", headerName: "State", flex: 1 },
    { field: "population", headerName: "Metro Area Population", flex: 1 },
    { field: "residential_population", headerName: "Residential Population", flex: 1 },
  ];

  const renderAssignedTerritory = (p) => {
    if (p.row.territory_name) {
      return (
        <Link
          href={"/territories/" + p.row.id}
          style={{ textDecoration: "none" }}
        >
          {p.row.territory_name}
        </Link>
      );
    } else {
      return "X - Unassigned";
    }
  };

  const columns = [
    {
      field: "territory_name",
      headerName: "Territory",
      flex: 1,
      editable: false,
      valueGetter: getTerritoryName,
      renderCell: (params) => renderAssignedTerritory(params),
    },
    {
      field: "population",
      headerName: "Population",
      flex: 1,
      editable: false,
    },
    {
      field: "assigned_user",
      headerName: "Coordinator",
      flex: 1,
      valueGetter: (params) => returnAssignedUser(params),
      editable: false,
    },
  ];

  if (isLoading) {
    return (
      <Container sx={{ mt: 4 }} align="center">
        {isLoading && <LinearProgress />}
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <div className="NotYetDeveloped">
        <Paper elevation={0} sx={{ p: 2 }}>
          <Typography variant="h5" mb={2} pb={0}>
            Territory Map
          </Typography>
          <Stack direction="row" spacing={1} mb={2} justifyContent="center">
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: "#6fbf73", color: "white !important" }}>
                  {openTerritories}
                </Avatar>
              }
              label={"Open"}
              variant="outlined"
            />
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: "#1876d1", color: "white !important" }}>
                  {managedTerritories}
                </Avatar>
              }
              label={"Managed"}
              variant="outlined"
            />
            <Chip
              avatar={<Avatar>{managedTerritories + openTerritories}</Avatar>}
              label={"Total"}
              variant="outlined"
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            mt={2}
            mb={2}
            justifyContent="center"
          >
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: "#f7fb3a", color: "black !important" }}>
                  {onboardingTerritories}
                </Avatar>
              }
              label={"Onboarding"}
              variant="outlined"
            />
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: "#ee9b2e", color: "black !important" }}>
                  {trainingdTerritories}
                </Avatar>
              }
              label={"Training"}
              variant="outlined"
            />
            <Chip
              avatar={
                <Avatar sx={{ bgcolor: "#1876d1", color: "black !important" }}>
                  {coordinatorTerritories}
                </Avatar>
              }
              label={"Active"}
              variant="outlined"
            />
          </Stack>
          <MapComponent />
          <Typography variant="h6" mb={2} mt={2} pb={0}>
            Territory Breakdown
          </Typography>
          <DataGridPro
            autoHeight
            editMode="row"
            density={"compact"}
            columns={columns}
            apiRef={apiRef}
            rows={territoryList}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            components={{ Toolbar: EditToolbar }}
            componentsProps={{
              toolbar: {
                apiRef,
              },
            }}
          />
        </Paper>
      </div>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
                textAlign="center"
              >
                {territoryData.territory_name}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Container maxWidth="xl" sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {/* <Paper elevation={2} sx={{ p: 2, pt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Total Population
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  <List dense={true}>{territoryData.territory_population}</List>
                </Paper> */}
                <Paper elevation={2} sx={{ mt:1, p: 2, pt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Current Coordinator
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  {territoryData.assigned_user_name ? (
                    <List dense={true}>
                      <Link
                        to={"/view/" + territoryData.assigned_user_id}
                        component={NavLink}
                      >
                        <ListItem id={1}>
                          <ListItemText
                            sx={{ mt: 0, pt: 0 }}
                            primary={territoryData.assigned_user_name}
                            secondary={territoryData.assigned_user_email}
                          />
                        </ListItem>
                      </Link>
                    </List>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      component="div"
                      textAlign="center"
                    >
                      None Assigned
                    </Typography>
                    
                  )}
                </Paper>
                <Paper elevation={2} sx={{ p: 2, pt: 1, mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Previous Coordinators
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  <List dense={true}>
                    {(territoryData.previous_users || []).map((item) => (
                      <ListItem>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
                <Paper elevation={2} sx={{ p: 2, mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                  <Link
                    href="https://www.google.com/maps/d/u/0/edit?mid=1PoyOkY7e-HI3wFvN1FKLoQ0G9Edu8M4&usp=sharing"
                    target="_blank"
                    rel="noopener"
                  >View Current Coordinator Locations
                  </Link>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  <Link
                    href="https://www.google.com/maps/d/u/0/edit?mid=1TYt_mGfomK4ZGPiTyQPJQGcpbkZLxxY&usp=sharing"
                    target="_blank"
                    rel="noopener"
                    
                  >View Current Job Locations
                  </Link>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <Paper elevation={2} sx={{ p: 2, pt: 1 }}> 
                <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Population Density by County
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Total Population - {territoryData.territory_population}
                  </Typography>
                  <Divider sx={{ mb: 2, mt: 1 }} />
                  <SmallMapComponent data={smallMapData} />
                </Paper>
              </Grid>
              {/* <Grid item xs={3}>
                <Paper elevation={2} sx={{ p: 2, pt: 1 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Counties
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  <List dense={true}>
                    <ListItem id={1}>
                      <ListItemText
                        sx={{ mt: 0, pt: 0 }}
                        primary={"Total Population"}
                        secondary={territoryData.territory_population}
                      />
                    </ListItem>
                    {(territoryData.territory_counties || []).map((item) => (
                      <ListItem id={item.fips_code}>
                        <ListItemText
                          sx={{ mt: 0, pt: 0 }}
                          primary={item.county_name}
                          secondary={item.population}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid> */}
              <Grid item xs={4}>
                <Paper elevation={2} sx={{ p: 2, pt: 1, mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    County List
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  {territoryData.territory_counties ? (
                    <DataGridPro
                      autoHeight
                      density={"compact"}
                      columns={small_columns}
                      rows={territoryData.territory_counties}
                      components={{ Toolbar: EditToolbar }}
                    />
                  ) : (
                    <div></div>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper elevation={2} sx={{ p: 2, pt: 1, mt: 2 }}>
                  <Typography
                    variant="subtitle2"
                    component="div"
                    textAlign="center"
                  >
                    Major Cities
                  </Typography>
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  {territoryData.territory_cities ? (
                    <DataGridPro
                      autoHeight
                      density={"compact"}
                      columns={city_columns}
                      rows={territoryData.territory_cities}
                      components={{ Toolbar: EditToolbar }}
                    />
                  ) : (
                    <div></div>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </div>
    </Container>
  );
}

export default Territories;
