import {
  Avatar,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../axiosApi";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import axios from "axios";
import { usePlacesWidget } from "react-google-autocomplete";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { forwardRef } from "react";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from "moment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Link from "@mui/material/Link";
import { List, ListItem } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from "@mui/icons-material/Preview";
import DatePicker from "@mui/lab/DatePicker";
//const baseURL = "https://management-portal-api.herokuapp.com/api/";
const baseURL = "http://127.0.0.1:8000/api/";

function AddUser() {
 
 
    
  const [userInfo, setUserInfo] = useState([]);
  const [notesList, setNotesList] = useState([]);
  const [territoryList, setTerritoryList] = useState([]);
  const [errorHandle, setErrorHandle] = useState({
    open: false,
    message: null,
    severity: null,
  });
  const [open, setOpen] = useState(false);
  const [selectedWelcomeDate, setSelectedWelcomeDate] = useState(null);
  const [selectedExamDate, setSelectedExamDate] = useState(null);
  const [selectedTermDate, setSelectedTermDate] = useState(null);
  const [selectedExpirationDate, setSelectedExpirationDate] = useState(null);
  const [selectedBirthDate, setSelectedBirthDate] = useState(null);
  const [selectedLaunchDate, setSelectedLaunchDate] = useState(null);
  const [postimage, setPostImage] = useState(null);
  const [newNote, setNewNote] = useState("");
  const [userUploads, setUserUploads] = useState([]);
  const [categoryChange, setCategoryChange] = useState(1);
  const [fileUpload, setFileUpload] = useState(null);
  const [buttonName, setButtonName] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorHandle({ ...errorHandle, open: false });
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const initialUserData = Object.freeze({});

  const [formData, setFormData] = useState(initialUserData);

  var usStates = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ];



  const handleChange = (e) => {
    if ([e.target.name] == "image") {
      setPostImage({
        image: e.target.files,
      });
    } else {
      setFormData((formData) => ({
        ...formData,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axiosInstance
    .post(`user/register/`, {
      email: formData.personal_email,
      first_name: formData.first_name,
      last_name: formData.last_name,
    })
    .then((result) => {
        console.log(result)
      window.location = '/';
    });


  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" my={4} pb={0} textAlign="center">
        Add Candidate
      </Typography>
      <Grid container spacing={1}>
        <Grid
          container
          item
          spacing={2}
          mb={4}
          alignContent="flex-start"
        >
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="first_name"
              name="first_name"
              label="First Name"
              value={formData.first_name || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="last_name"
              name="last_name"
              label="Last Name"
              value={formData.last_name || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="personal_email"
              name="personal_email"
              label="Personal Email"
              value={formData.personal_email || ""}
              onChange={handleChange}
            />
          </Grid>
         
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Add Candidate
            </Button>
          </Grid>
        </Grid>


        
      </Grid>
      <Snackbar
        open={errorHandle.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={errorHandle.severity}
          sx={{ width: "100%" }}
        >
          {errorHandle.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AddUser;
